import React from 'react'
import Assets from '../../constants/images'
import { AiOutlinePlus } from "react-icons/ai"
import { Link } from 'react-router-dom'

const CreatePostBox = () => {
    return (
        <div className="create-post-box text-center">
            <div>
                <Link to="/create-post">
                    <div className='img-wrapper'>
                        <img className='w-100 h-100' src={Assets.CreatePostImg} alt="" />
                    </div>
                    <div className="mt-20">
                        <h2 className="w-400 mb-80">No post to show</h2>
                        <AiOutlinePlus className='icon' />
                        <h6 className="mt-20">Click here to create post</h6>
                    </div>
                </Link>
            </div>
        </div>

    )
}

export default CreatePostBox