import React from 'react'
import { useState } from 'react'
import { Modal, Button, Form, Col, Row } from "react-bootstrap"
import { useForm } from 'react-hook-form'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { NavLink, Link, useNavigate } from 'react-router-dom'
import Assets from '../../constants/images'
import { Times } from '../../constants/svgs'

const NotificationPopup = ({ notificationPopup, setNotificationPopup }) => {
    const navigate = useNavigate()
    const [searchText, setSearchText] = useState("")

    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const onSubmit = () => {
        setNotificationPopup(false)
    }

    const handleSearch = (e) => {
        setSearchText(e.target.value)
    }

    const returnMsg = (type) => {
        let msg = ""

        if (type === "request") {
            msg = "sent a following request"
        }
        else if (type === "message") {
            msg = "send a message to you"
        }
        else {
            msg = "liked your picture"
        }
        return msg
    }

    const [notifications, setNotifications] = useState([
        {
            img: Assets.Avatar12,
            name: "Patrick Nelson",
            time:"12min",
            notification_type: "request",
            status: "0"
        },
        {
            img: Assets.Avatar7,
            name: "George Nelson",
            time:"12min",
            notification_type: "message",
            status: "0"
        },
        {
            img: Assets.Avatar8,
            name: "Amanda Parker",
            time:"12min",
            notification_type: "like",
            status: "0"
        },
        {
            img: Assets.Avatar9,
            name: "Haris Roundback",
            time:"12min",
            notification_type: "message",
            status: "0"
        },
        {
            img: Assets.Avatar10,
            name: "James Colin",
            time:"12min",
            notification_type: "like",
            status: "1"
        },
        {
            img: Assets.Avatar11,
            name: "Taric James",
            time:"12min",
            notification_type: "message",
            status: "1"
        },
        {
            img: Assets.Avatar12,
            name: "John Nelson",
            time:"12min",
            notification_type: "like",
            status: "1"
        },
    ])

    return (
        <Modal
            className='theme-popup notification-popup'
            show={notificationPopup}
            onHide={() => setNotificationPopup(false)}
            size="md"
            centered
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Body>
                <div className="wrapper">
                    <div className='d-flex text-center mb-20 pb-30 border-bottom'>
                        <h3 className='w-100 d-inline-block'>NOTIFICATIONS <span>(4)</span></h3>
                        <div className="text-end times-icon-wrapper" onClick={() => setNotificationPopup(false)}>
                            <Times />
                        </div>
                    </div>

                    <div className='notification-container'>
                        <div className='search-wrapper mb-3'>
                            <Form.Control
                                type="text"
                                placeholder="Search"
                                className="search-input"
                                aria-label="Search"
                                value={searchText}
                                onChange={(e) => handleSearch(e)}
                                onKeyDown={(e) => e.keyCode === 13 && setSearchText("")}
                            />
                        </div>

                        <div className="notification-wrapper">
                            {
                                notifications.map((data, index) => (
                                    <div key={index} className="box pb-3 mb-3 border-bottom">
                                        <div className="d-flex">
                                            <div className="profile-wrapper me-2">
                                                <img src={data.img} onError={e => e.target.src = Assets.ProfilePlaceholder} alt="" />
                                            </div>

                                            <div className="custom-position text-end w-100">
                                                <small>{data.time}</small>
                                                <div className="text-start">
                                                    <h6 className=''>{data.name} <span className={`${data.status === "1" && "text-grey"}`}>{returnMsg(data.notification_type)}</span></h6>
                                                    <div className={`btn-wrapper ${data.notification_type === "request" ? "d-block" : "d-none"}`}>
                                                        <Button className="me-2 d-inline-block btn-solid green">CONFIRM</Button>
                                                        <Button className="d-inline-block btn-solid red">DELETE</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default NotificationPopup