import React, { useState } from 'react'
import { useContext } from 'react'
import { Form } from 'react-bootstrap'
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { NavLink } from 'react-router-dom'
import Assets from '../../constants/images'
import { HeartOutline, HeartFill, WishListOutline, WishListFill } from '../../constants/svgs'
import { LazyLoadImage } from 'react-lazy-load-image-component';

const MassonaryCard = ({ link1, link2, data, setData }) => {
    const [isWishlistChecked, setIsWishlistChecked] = useState(false)
    const [isHeartChecked, setIsHeartChecked] = useState(false)

    const handleLike = (index) => {
        data[index].heart = !data[index].heart
        setData([...data])
    }

    const handleWishlist = (index) => {
        data[index].wishlist = !data[index].wishlist
        setData([...data])
    }

    return (
        data.map((data, index) => (
            <div className='item massonary-card' key={index}>
                <NavLink
                    state={{ data: data }}
                    to={`/${link1}/${data.user_id}`}
                >
                    <div className='d-flex align-items-center mb-2'>
                        <div className="profile-img-wrapper">
                            <img onError={(e) => e.target.src = Assets.ProfilePlaceholder} src={data.user_img} alt="" />
                        </div>
                        <h6 className='ms-2'><b>{data.name}</b></h6>
                    </div>
                </NavLink>

                <NavLink
                    state={{ data: data }}
                    to={`/${link2}/${data.id}`}
                >
                    <div className='post-img'>
                        <img className='w-100' src={data.img} onError={e => e.target.src = Assets.PostImagePlaceholder} alt="" />
                    </div>
                </NavLink>

                <div className='d-flex align-items-center ps-2 mt-1'>
                    <div>
                        <label htmlFor={`heart-${index}`}>
                            <div className='icon-wrapper me-4'>
                                <input value={data.heart} name={`heart-${index}`} onChange={e => handleLike(index)} id={`heart-${index}`} className='d-none' type="checkbox" />
                                {
                                    data.heart ?
                                        <HeartFill /> :
                                        <HeartOutline />
                                }
                                <span className='ms-2'></span>
                            </div>
                        </label>
                    </div>

                    <div className='ms-2'>
                        <label htmlFor={`wishlist${index}`}>
                            <div className='icon-wrapper me-4'>
                                <input value={data.wishlist} name={`wishlist-${index}`} onChange={e => handleWishlist(index)} id={`wishlist${index}`} className='d-none' type="checkbox" />
                                {
                                    data.wishlist ?
                                        <WishListFill /> :
                                        <WishListOutline />
                                }
                                <span className='ms-2'></span>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        ))
    )
}

export default MassonaryCard