import React from 'react'
import { Container, Row, Col } from "react-bootstrap"
import Assets from '../constants/images'
import DashboardBreadCrumb from '../layouts/dashboard/DashboardBreadCrumb'

const AboutPage = () => {
    return (
        <>
            <DashboardBreadCrumb link1="/home" link1Text="Home" link2="#" link2Text="About Us" link3="" link3Text="" link4="" link4Text="" />

            <div className="pages about-page">
                <Container fluid>
                    <Row>
                        <Col lg={6}>
                            <div className="wrapper">
                                <h1>About Linkfront</h1>
                                <p className="mt-2">
                                    This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text. Only for show. He who searches for meaning here will be sorely disappointed. These words are here to provide the reader with a basic impression of how actual text will appear in its final presentation.
                                    <br /><br />
                                    This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text. Only for show. He who searches for meaning here will be sorely disappointed. These words are here to provide the reader with a basic impression of how actual text will appear in its final presentation. This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text. Only for show. He who searches for meaning here will be sorely disappointed. These words are here to provide the reader with a basic impression of how actual text will appear in its final presentation.
                                    <br /><br />
                                    This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text. Only for show. He who searches for meaning here will be sorely disappointed. These words are here to provide the reader with a basic impression of how actual text will appear in its final presentation.  This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text.
                                    <br /><br />
                                    This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text. Only for show. He who searches for meaning here will be sorely disappointed. These words are here to provide the reader with a basic impression of how actual text will appear in its final presentation.
                                </p>
                            </div>
                        </Col>

                        <Col className="my-4 mt-lg-0" lg={{ span: 5, offset: 1 }}>
                            <div className="img-wrapper text-center p-5 text-center d-flex align-items-center justify-contnet-center h-100">
                                <img className="mx-auto img-fluid" src={Assets.LogoVerticalXl} alt="" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default AboutPage