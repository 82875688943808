import React, { useState, useContext } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { TiTimes } from 'react-icons/ti'
import Assets from "../../constants/images"
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay, Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import SelectImageCard from "../../components/dashboard/SelectImageCard"
import { Link } from "react-router-dom"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { AddProductContext } from '../../App'

const AddedPostCard = ({ showSelectedImageBox, Card, link }) => {
    const [addProduct, setAddProduct] = useContext(AddProductContext)
    const [posts, setPosts] = useState([
        {
            id: 1,
            img: Assets.PostImage1,
            isChecked: false
        },
        {
            id: 2,
            img: Assets.PostImage5,
            isChecked: false
        },
        {
            id: 3,
            img: Assets.PostImage11,
            isChecked: false
        },
        {
            id: 4,
            img: Assets.PostImage12,
            isChecked: false
        },
        {
            id: 5,
            img: Assets.PostImage13,
            isChecked: false
        },
        {
            id: 6,
            img: Assets.PostImage18,
            isChecked: false
        },
        {
            id: 7,
            img: Assets.PostImage19,
            isChecked: false
        },
        {
            id: 8,
            img: Assets.PostImage20,
            isChecked: false
        },
        {
            id: 9,
            img: Assets.PostImage21,
            isChecked: false
        },
        {
            id: 10,
            img: Assets.PostImage22,
            isChecked: false
        },
        {
            id: 11,
            img: Assets.PostImage23,
            isChecked: false
        },
    ])

    const removeSelectedPost = (index) => {
        addProduct.splice(index, 1)
        setAddProduct([...addProduct])
    }

    return (
        <div className="added-post-card">
            <ul className="nav nav-pills" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="pills-Fashion-tab" data-bs-toggle="pill" data-bs-target="#pills-Fashion" type="button" role="tab" aria-controls="pills-Fashion" aria-selected="true">Fashion</button>
                </li>

                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="pills-Shoes-tab" data-bs-toggle="pill" data-bs-target="#pills-Shoes" type="button" role="tab" aria-controls="pills-Shoes" aria-selected="true">Shoes</button>
                </li>

                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="pills-HouseHold-tab" data-bs-toggle="pill" data-bs-target="#pills-HouseHold" type="button" role="tab" aria-controls="pills-HouseHold" aria-selected="true">HouseHold</button>
                </li>

                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="pills-CameraRoll-tab" data-bs-toggle="pill" data-bs-target="#pills-CameraRoll" type="button" role="tab" aria-controls="pills-CameraRoll" aria-selected="true">Camera Roll</button>
                </li>
            </ul>

            <div className={`center-box p-3 mt-3 d-flex flex-column flex-lg-row align-items-lg-center justify-content-between ${!showSelectedImageBox && "d-none"}`}>
                <div className='me-1'>
                    <span>SELECTED IMAGES</span>
                </div>

                <div className="swipper-wrapper ms-0 ms-lg-3">
                    <Swiper
                        modules={[Navigation]}
                        spaceBetween={10}
                        centerMode={true}
                        breakpoints={{
                            200: {
                                slidesPerView: 2,
                            },
                            300: {
                                slidesPerView: 3,
                            },
                            640: {
                                slidesPerView: 5,
                            },
                            768: {
                                slidesPerView: 6,
                            },
                            992: {
                                slidesPerView: 8,
                            },
                            1200: {
                                slidesPerView: 10,
                            },
                            1300: {
                                slidesPerView: 10,
                            },
                        }}
                    >
                        {
                            addProduct.map((data, index) => (
                                <SwiperSlide key={index}>
                                    <div className='img-wrapper me-2'>
                                        <div className='icon-times-wrapper d-flex align-items-center justify-content-center' onClick={() => removeSelectedPost(index)}>
                                            <TiTimes className="icon" />
                                        </div>
                                        <img onError={(e) => e.target.src = Assets.GeneralPlaceholder} src={data.img} alt="" />
                                    </div>
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                </div>

                <div>
                    <Link to="/create-post" className="text-center d-block ms-lg-auto mt-3 mt-lg-0 btn-solid done-btn">DONE</Link>
                </div>
            </div>

            <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-Fashion" role="tabpanel" aria-labelledby="pills-Fashion-tab">
                    <Row>
                        {
                            posts.map((data, index) => (
                                <Col key={index} xs={6} sm={4} md={4} lg={3} xl={2} className="mt-4">
                                    <Card data={data} link={link} />
                                </Col>
                            ))
                        }
                    </Row>
                </div>

                <div className="tab-pane fade" id="pills-Shoes" role="tabpanel" aria-labelledby="pills-Shoes-tab">
                    <Row>
                        {
                            posts.map((data, index) => (
                                <Col key={index} xs={6} sm={4} md={4} lg={3} xl={2} className="mt-4">
                                    <Card data={data} link={link} />
                                </Col>
                            ))
                        }
                    </Row>
                </div>

                <div className="tab-pane fade" id="pills-HouseHold" role="tabpanel" aria-labelledby="pills-HouseHold-tab">
                    <Row>
                        {
                            posts.map((data, index) => (
                                <Col key={index} xs={6} sm={4} md={4} lg={3} xl={2} className="mt-4">
                                    <Card data={data} link={link} />
                                </Col>
                            ))
                        }
                    </Row>
                </div>

                <div className="tab-pane fade" id="pills-CameraRoll" role="tabpanel" aria-labelledby="pills-CameraRoll-tab">
                    <Row>
                        {
                            posts.map((data, index) => (
                                <Col key={index} xs={6} sm={4} md={4} lg={3} xl={2} className="mt-4">
                                    <Card data={data} link={link} />
                                </Col>
                            ))
                        }
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default AddedPostCard