import React, { useState } from 'react'
import { Button, FloatingLabel, Form } from 'react-bootstrap'
import Assets from '../../constants/images'
import { FaUserAlt, FaMapMarkerAlt } from "react-icons/fa"
import { MdEmail } from "react-icons/md"
import { AiFillLock } from "react-icons/ai"
import { VscEye, VscEyeClosed } from "react-icons/vsc"
import { NavLink, useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { SPECIAL_CHARACTER_ARRAY, VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'
import { success } from '../../constants/msg'
import { ToastContainer } from 'react-toastify';
import { BrandFill, ClockFill, GlobeFill, LocationFill, LockFill, MessageIconFill, UserFill } from '../../constants/svgs'
import BusinessHourPopup from '../popup/BusinessHourPopup'
import moment from 'moment'
import Select from 'react-select';

const SignupBrandForm = () => {
    const navigate = useNavigate()
    const [showPassword, setShowPassword] = useState(false)
    const [businessHoursPopup, setBusinessHoursPopup] = useState(false);
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [timeError, setTimeError] = useState(false)
    const [options, setOptions] = useState([
        { value: '1', label: 'Category 1' },
        { value: '2', label: 'Category 2' },
        { value: '3', label: 'Category 3' },
    ])

    const { register, handleSubmit, watch, setValue, control, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const onSubmit = (data) => {
        // convert start and end time into timestamp than compare
        // also compare that the start and end time should not be empty

        if (((moment(startTime).format("X") >= moment(endTime).format("X")) || (!startTime || !endTime))) {
            setTimeError(true)  // if error goes here
        }
        else {
            success("Signup Successfull")
            setTimeout(() => {
                navigate("/account-verification")
            }, 1500);
        }
    }

    const [file, setFile] = useState({
        file: null,
        file_url: null
    })

    const uploadImage = (e) => {
        setFile({
            file: e.target.files[0],
            file_url: URL.createObjectURL(e.target.files[0])
        })
    }

    return (
        <div className='auth-card signup-form'>
            <ToastContainer />
            <BusinessHourPopup setTimeError={setTimeError} businessHoursPopup={businessHoursPopup} setBusinessHoursPopup={setBusinessHoursPopup} startTime={startTime} setStartTime={setStartTime} endTime={endTime} setEndTime={setEndTime} />

            <div>
                <h3 className='pb-30 border-bottom mb-30'>SIGN UP</h3>
                <p className='text-black'>Transforming shopping into an experience.</p>
            </div>

            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className='mt-30'>
                    <label className='profile-img-wrapper' htmlFor="profile">
                        <input className="d-none" onChange={e => uploadImage(e)} type="file" id="profile" name="profile" accept="image/*" />
                        <img src={file.file_url ? file.file_url : Assets.ProfileUploadPlaceholder} alt="" />
                    </label>
                    <p className='mt-2'>Upload profile picture</p>
                </div>

                <div className='mt-30'>
                    <FloatingLabel controlId="username" label="User Name *">
                        <Form.Control
                            onKeyDown={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key) && e.preventDefault()) && e.preventDefault()}
                            maxLength={VALIDATIONS.NAME}
                            type="text"
                            placeholder="User Name *"
                            {...register("username",
                                {
                                    maxLength: {
                                        value: VALIDATIONS.NAME,
                                        message: VALIDATIONS_TEXT.NAME_MAX
                                    },
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.USER_NAME_REQUIRED
                                    },
                                })
                            }
                        />
                        <div className="icon-wrapper">
                            <UserFill className='icon' />
                        </div>
                    </FloatingLabel>
                    {errors.username && <small className='text-start d-block text-danger mt-1'>{errors.username.message}</small>}

                    <FloatingLabel className="mt-20" controlId="name" label="Brand Name *">
                        <Form.Control
                            onKeyDown={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key) && e.preventDefault()) && e.preventDefault()}
                            maxLength={VALIDATIONS.NAME}
                            type="text"
                            placeholder="Brand name *"
                            {...register("brand_name",
                                {
                                    maxLength: {
                                        value: VALIDATIONS.NAME,
                                        message: VALIDATIONS_TEXT.NAME_MAX
                                    },
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.BRAND_NAME_REQUIRED
                                    },
                                })
                            }
                        />
                        <div className="icon-wrapper">
                            <UserFill className='icon' />
                        </div>
                    </FloatingLabel>
                    {errors.brand_name && <small className='text-start d-block text-danger mt-1'>{errors.brand_name.message}</small>}

                    <div className="react-select">
                        <div className="icon-wrapper">
                            <BrandFill className='icon' />
                        </div>

                        <Controller
                            control={control}
                            name="brand_category"
                            rules={{
                                required: {
                                    value: true,
                                    message: VALIDATIONS_TEXT.BRAND_CATEGORY_REQUIRED,
                                },
                            }}
                            render={({field})=>(
                                <Select
                                    {...field}
                                    placeholder="Brand Category *"
                                    className="mt-20 form-control"
                                    classNamePrefix="react-select-container"
                                    isSearchable={false}
                                    options={options}
                                />
                            )}
                        />
                    </div>
                    {errors.brand_category && <small className='text-start d-block text-danger mt-1'>{errors.brand_category.message}</small>}

                    <FloatingLabel className="mt-20" controlId="businessHours" label="Business Hours *" onClick={() => setBusinessHoursPopup(true)}>
                        <Form.Control
                            value={(startTime && endTime) && `${moment(startTime).format("hh:mm a")} - ${moment(endTime).format("hh:mm a")}`}
                            type="text"
                            placeholder="Business Hours *"
                            readOnly
                        />
                        <div className="icon-wrapper">
                            <ClockFill className='icon' />
                        </div>
                    </FloatingLabel>
                    {timeError && <small className='text-start d-block text-danger mt-1'>{(!startTime || !endTime) ? "Business hours is required" : "Opening time should be less than closing time"}</small>}

                    <FloatingLabel className="mt-20" controlId="websiteUrl" label="Website URL *">
                        <Form.Control
                            type="text"
                            placeholder="Website URL *"
                            {...register("website_url",
                                {
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.WEBSITE_URL_REQUIRED
                                    },
                                    pattern: {
                                        value: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)/,
                                        message: VALIDATIONS_TEXT.INVALID_WEBSITE_URL
                                    }
                                })
                            }
                        />
                        <div className="icon-wrapper">
                            <GlobeFill className='icon' />
                        </div>
                    </FloatingLabel>
                    {errors.website_url && <small className='text-start d-block text-danger mt-1'>{errors.website_url.message}</small>}

                    <FloatingLabel className="mt-20" controlId="address" label="Address *">
                        <Form.Control
                            maxLength={VALIDATIONS.ADDRESS_MAX}
                            type="text"
                            placeholder="Address *"
                            {...register("address",
                                {
                                    maxLength: {
                                        value: VALIDATIONS.ADDRESS_MAX,
                                        message: VALIDATIONS_TEXT.ADDRESS_MAX
                                    },
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.ADDRESS_REQUIRED
                                    },
                                })
                            }
                        />
                        <div className="icon-wrapper">
                            <LocationFill className='icon' />
                        </div>
                    </FloatingLabel>
                    {errors.address && <small className='text-start d-block text-danger mt-1'>{errors.address.message}</small>}


                    <FloatingLabel className="mt-20" controlId="email" label="Email address *">
                        <Form.Control
                            maxLength={VALIDATIONS.EMAIL}
                            type="email"
                            placeholder="Email address *"
                            {...register("email",
                                {
                                    maxLength: {
                                        value: VALIDATIONS.EMAIL,
                                        message: VALIDATIONS_TEXT.EMAIL_MAX
                                    },
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.EMAIL_REQUIRED
                                    },
                                    pattern: {
                                        value: /\S+@\S+\.\S+/,
                                        message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                    }
                                })
                            }
                        />
                        <div className="icon-wrapper">
                            <MessageIconFill className='icon' />
                        </div>
                    </FloatingLabel>
                    {errors.email && <small className='text-start d-block text-danger mt-1'>{errors.email.message}</small>}


                    <FloatingLabel className="mt-20" controlId="password" label="Password *">
                        <Form.Control
                            minLength={VALIDATIONS.PASSWORD_MIN}
                            maxLength={VALIDATIONS.PASSWORD_MAX}
                            type={`${showPassword ? "text" : "password"}`}
                            placeholder="Password *"
                            {...register("password",
                                {
                                    minLength: {
                                        value: VALIDATIONS.PASSWORD_MIN,
                                        message: VALIDATIONS_TEXT.PASSWORD_MIN
                                    },
                                    maxLength: {
                                        value: VALIDATIONS.PASSWORD_MAX,
                                        message: VALIDATIONS_TEXT.PASSWORD_MAX
                                    },
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.PASSWORD_REQUIRED
                                    },
                                    pattern: {
                                        value: /^(?=.*?[A-Z])(?=.*?[a-z]).{8,}$/,
                                        message: VALIDATIONS_TEXT.PASSWORD_FORMAT
                                    }
                                })
                            }
                        />
                        <div className="icon-wrapper">
                            <LockFill className='icon' />
                        </div>

                        <div className="icon-wrapper-right" onClick={() => setShowPassword(!showPassword)}>
                            {
                                showPassword ?
                                    <VscEyeClosed className='icon' />
                                    :
                                    <VscEye className='icon' />
                            }
                        </div>
                    </FloatingLabel>
                    {errors.password && <small className='text-start d-block text-danger mt-1'>{errors.password.message}</small>}


                    <Button type="submit" className='mt-40 mb-30 btn-solid'>SIGN UP</Button>
                    <span className="mb-20 d-block">Already have an account? <NavLink className="link" to="/signin">SIGN IN</NavLink></span>
                </div>
            </Form>
        </div>
    )
}

export default SignupBrandForm