import React from 'react'
import { NavLink } from 'react-router-dom'
import { Form } from 'react-bootstrap'
import { PrivacyFill, TermsAndConditionFill, LockFill, EyeFill } from "../../constants/svgs"

const SettingSidebar = () => {
    return (
        <div className='setting-sidebar h-100'>
            <div className="wrapper">
                <div className='title-wrapper border-bottom pb-30 mb-2 text-center'>
                    <h3>SETTINGS</h3>
                </div>

                <div className='link-container'>
                    <ul>
                        <li className="border-bottom pb-2 mb-2">
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                    <div className="icon-wrapper me-3">
                                        <EyeFill />
                                    </div>
                                    <div className=''>
                                        <span className='d-inline-block'>Privacy <span className='w-300'>(Public/Private)</span></span>

                                    </div>
                                </div>

                                <div className='text-end'>
                                    <Form.Check
                                        className="p-0"
                                        type="switch"
                                        id="custom-switch"
                                    />
                                </div>
                            </div>
                        </li>

                        <li className="border-bottom pb-2 mb-2">
                            <NavLink to="/settings/change-password">
                                <div className="d-flex align-items-center">
                                    <div className="icon-wrapper me-3">
                                        <LockFill />
                                    </div>
                                    <div className=''>
                                        <span className='d-inline-block'>Change Password</span>
                                    </div>
                                </div>
                            </NavLink>
                        </li>

                        <li className="border-bottom pb-2 mb-2">
                            <NavLink to="/settings/terms-and-conditions">
                                <div className="d-flex align-items-center">
                                    <div className="icon-wrapper me-3">
                                        <TermsAndConditionFill />
                                    </div>
                                    <div className=''>
                                        <span className='d-inline-block'>Terms And Conditions</span>

                                    </div>
                                </div>
                            </NavLink>
                        </li>

                        <li className="border-bottom pb-2 mb-2">
                            <NavLink to="/settings/privacy-policy">
                                <div className="d-flex align-items-center">
                                    <div className="icon-wrapper me-3">
                                        <PrivacyFill />
                                    </div>
                                    <div className=''>
                                        <span className='d-inline-block'>Privacy Policy</span>
                                    </div>
                                </div>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default SettingSidebar