import React from 'react'
import { Modal, Button } from "react-bootstrap"
import { NavLink, Link, Navigate, useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import Assets from '../../constants/images'
import { success } from '../../constants/msg'

const LogoutPopup = ({ logoutPopup, setLogoutPopup }) => {
    const navigate = useNavigate()

    const logout = () => {
        setLogoutPopup(false)
        success("Logout successfully")
        setTimeout(() => {
            navigate("/signin")
        }, 1500);
    }

    return (
        <>
            <ToastContainer />
            <Modal
                className='theme-popup logout-popup'
                show={logoutPopup}
                onHide={() => setLogoutPopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="wrapper">
                        <div className='text-center mb-30 pb-30 border-bottom'>
                            <h3 className='w-100 d-inline-block'>LOGOUT ALERT</h3>
                        </div>

                        <div className='content-container text-center'>
                            <div>
                                <p>
                                    Are you sure, you want to Log Out <br />
                                    of your The Link Front account?
                                </p>
                            </div>

                            <div className="mt-5">
                                <Button onClick={() => logout()} className="w-100 btn-solid mb-2">LOGOUT</Button>
                                <Button className="w-100 btn-transparent" onClick={() => setLogoutPopup(false)}>CANCEL</Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default LogoutPopup