import React, { useState } from 'react'
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap'
import Assets from '../constants/images'
import WishlistImageCard from '../components/dashboard/WishlistImageCard'
import DashboardBreadCrumb from '../layouts/dashboard/DashboardBreadCrumb'

const RecentActivityPage = () => {
    const [wishList, setWishList] = useState([
        {
            img: Assets.FavPostImg1,
            isChecked: false
        },
        {
            img: Assets.FavPostImg2,
            isChecked: false
        },
        {
            img: Assets.FavPostImg3,
            isChecked: false
        },
        {
            img: Assets.FavPostImg4,
            isChecked: false
        },
        {
            img: Assets.FavPostImg5,
            isChecked: false
        },
        {
            img: Assets.FavPostImg6,
            isChecked: false
        },
        {
            img: Assets.FavPostImg7,
            isChecked: false
        },
        {
            img: Assets.FavPostImg8,
            isChecked: false
        },
        {
            img: Assets.FavPostImg9,
            isChecked: false
        },
        {
            img: Assets.FavPostImg10,
            isChecked: false
        },
        {
            img: Assets.FavPostImg11,
            isChecked: false
        },
        {
            img: Assets.FavPostImg12,
            isChecked: false
        },
    ])

    return (
        <>
            <DashboardBreadCrumb link1="/home" link1Text="Home" link2="#" link2Text="Recent Activity" link3="" link3Text="" link4="" link4Text="" />
            <div className='pages recent-activity-page'>
                <div className='pb-4'>
                    <Container>
                        <Row>
                            {
                                wishList.map((data, index) => (
                                    <Col key={index} xs={6} md={4} lg={3} xl={2} className="mt-4">
                                        <WishlistImageCard link="/recent-activity/post" data={data} />
                                    </Col>
                                ))
                            }
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default RecentActivityPage