import React, { useState } from 'react'
import CreatePostBox from "../components/dashboard/CreatePostBox"
import { Container, Row, Col } from "react-bootstrap"
import Assets from '../constants/images'
import MassonaryCard from "../components/dashboard/MassonaryCard"

const EmptyHomePage = () => {
    // const [posts, setPosts] = useState([])
    const [posts, setPosts] = useState([
        {
            user_img: Assets.Avatar1,
            id: "1",
            name: "Elizabeth Thomas",
            img: Assets.PostImage10,
        },
        {
            user_img: Assets.Avatar2,
            id: "2",
            name: "Elizabeth Thomas",
            img: Assets.PostImage1
        },
        {
            user_img: Assets.Avatar3,
            id: "3",
            name: "Elizabeth Thomas",
            img: Assets.PostImage2
        },
        {
            user_img: Assets.Avatar4,
            id: "4",
            name: "Elizabeth Thomas",
            img: Assets.PostImage18
        },
        {
            user_img: Assets.Avatar5,
            id: "5",
            name: "Elizabeth Thomas",
            img: Assets.PostImage3
        },
        {
            user_img: Assets.Avatar6,
            id: "6",
            name: "Elizabeth Thomas",
            img: Assets.PostImage11
        },
        {
            user_img: Assets.Avatar1,
            id: "7",
            name: "Elizabeth Thomas",
            img: Assets.PostImage4
        },
        {
            user_img: Assets.Avatar3,
            id: "9",
            name: "Elizabeth Thomas",
            img: Assets.PostImage5
        },
        {
            user_img: Assets.Avatar4,
            id: "10",
            name: "Elizabeth Thomas",
            img: Assets.PostImage6
        },
        {
            user_img: Assets.Avatar5,
            id: "11",
            name: "Elizabeth Thomas",
            img: Assets.PostImage15
        },
        {
            user_img: Assets.Avatar6,
            id: "12",
            name: "Elizabeth Thomas",
            img: Assets.PostImage7
        },
        {
            user_img: Assets.Avatar1,
            id: "13",
            name: "Elizabeth Thomas",
            img: Assets.PostImage13
        },
        {
            user_img: Assets.Avatar2,
            id: "14",
            name: "Elizabeth Thomas",
            img: Assets.PostImage26
        },
    ]);

    return (
        <>
            <div className='pages empty-home-page'>
                <div className='d-flex align-items-center justify-content-center h-100'>
                    <CreatePostBox />
                </div>
            </div>
        </>
    )
}

export default EmptyHomePage