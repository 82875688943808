export const roles = {
    shopper:1,
    brand:2
}

export const constant = {
    DEVICE_TYPE: "web",
    DEVICE_TOKEN: "",
    LOCAL_STORAGE_TOKEN: 'LINK_FRONT_AUTH_ACCESS_TOKEN',
    LOCAL_STORAGE_USER: 'LINK_FRONT_USER_DATA',
    LOCAL_STORAGE_ROLE: 'LINK_FRONT_USER_ROLE',
    BASE_URL: process.env.REACT_APP_BASE_URL,
    DIR_NAME:""
}

export const apiUrl = {
    login: '/login',
    register:'/register',
    logout: '/logout',
}

export const s3Credential = {
    bucketName: "",
    region: "",
    dirName:"",
    accessKeyId: "",
    secretAccessKey: "",
    s3EndPoint: "",
    fileSize: ""
}

export const errorMessages = {
    fileSize: "The file size is too large",
    fileSuccess: "file uploaded successfully",
    fileError: "Error in uploading file",
}
