import React, { useState } from 'react'
import CreatePostBox from "../components/dashboard/CreatePostBox"
import { Container, Row, Col } from "react-bootstrap"
import Assets from '../constants/images'
import MassonaryCard from "../components/dashboard/MassonaryCard"
import DashboardBreadCrumb from '../layouts/dashboard/DashboardBreadCrumb'
import { POPUP_TYPE } from '../constants/app-constants'
import { FaClosedCaptioning } from 'react-icons/fa'

const BrandPage = () => {
    const [data, setData] = useState([
        {
            user_img: Assets.BrandAvatar1,
            id: "1",
            name: "Gucci",
            user_id: "1",
            img: Assets.BrandImage1,
            heart: true,
            wishlist: true
        },
        {
            user_img: Assets.BrandAvatar2,
            id: "2",
            name: "Proda",
            user_id: "2",
            img: Assets.BrandImage4,
            heart: false,
            wishlist: true
        },
        {
            user_img: Assets.BrandAvatar3,
            id: "3",
            name: "Supreme",
            user_id: "3",
            img: Assets.BrandImage6,
            heart: true,
            wishlist: false
        },
        {
            user_img: Assets.BrandAvatar4,
            id: "4",
            name: "Nike",
            user_id: "4",
            img: Assets.BrandImage9,
            heart: true,
            wishlist: true
        },
        {
            user_img: Assets.BrandAvatar5,
            id: "5",
            name: "Calvin Klein",
            user_id: "5",
            img: Assets.BrandImage7,
            heart: false,
            wishlist: true
        },
        {
            user_img: Assets.BrandAvatar6,
            id: "6",
            name: "Brooks Brother",
            user_id: "6",
            img: Assets.BrandImage11,
            heart: true,
            wishlist: false
        },
        {
            user_img: Assets.BrandAvatar1,
            id: "7",
            name: "Converse",
            user_id: "7",
            img: Assets.BrandImage8,
            heart: false,
            wishlist: true
        },
        {
            user_img: Assets.BrandAvatar3,
            id: "9",
            name: "Tommy Hilfiger",
            user_id: "8",
            img: Assets.BrandImage5,
            heart: false,
            wishlist: false
        },
        {
            user_img: Assets.BrandAvatar4,
            id: "10",
            name: "Eddie Bouer",
            user_id: "9",
            img: Assets.BrandImage3,
            heart: false,
            wishlist: false
        },
        {
            user_img: Assets.BrandAvatar5,
            id: "11",
            name: "Under Armour",
            user_id: "10",
            img: Assets.BrandImage10,
            heart: false,
            wishlist: false
        },
        {
            user_img: Assets.BrandAvatar6,
            id: "12",
            name: "Polo Rolph Louren",
            user_id: "11",
            img: Assets.BrandImage2,
            heart: true,
            wishlist: true
        },
        {
            user_img: Assets.BrandAvatar1,
            id: "13",
            name: "Abercrombie & Fitch",
            user_id: "12",
            img: Assets.BrandImage12,
            heart: true,
            wishlist: true
        },
        {
            user_img: Assets.BrandAvatar2,
            id: "14",
            name: "Banana Republic",
            user_id: "13",
            img: Assets.BrandImage13,
            heart: true,
            wishlist: true
        },
        {
            user_img: Assets.BrandAvatar3,
            id: "14",
            name: "Gamine Workwear",
            user_id: "14",
            img: Assets.BrandImage14,
            heart: true,
            wishlist: true
        },
    ]);

    return (
        <>
            <DashboardBreadCrumb link1="/home" link1Text="Home" link2="#" link2Text="Brands" link3="" link3Text="" link4="" link4Text="" popupType={POPUP_TYPE.filter} />
            <div className='pages brand-page'>
                <div className='pb-3'>
                    <Container fluid>
                        <Row>
                            <Col xs={12}>
                                <div className='massonary-grid'>
                                    <MassonaryCard link1="brand" link2="brand/post" data={data} setData={setData} />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default BrandPage