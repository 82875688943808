import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link } from 'react-router-dom'
import Assets from '../../constants/images'
import { Times } from '../../constants/svgs'
import UnblockAlertPopup from '../popup/UnblockAlertPopup'

const DemographicsCard = () => {
    const [demographicsData, setDemographicsData] = useState([
        {
            img: Assets.Avatar1,
            name: "George Nelson"
        },
        {
            img: Assets.Avatar2,
            name: "Patrick Nelson"
        },
        {
            img: Assets.Avatar3,
            name: "Amanda Nelson"
        },
        {
            img: Assets.Avatar4,
            name: "Haris Nelson"
        },
        {
            img: Assets.Avatar1,
            name: "James Nelson"
        },
        {
            img: Assets.Avatar2,
            name: "Taric Nelson"
        },
        {
            img: Assets.Avatar3,
            name: "Haris Nelson"
        },
        {
            img: Assets.Avatar4,
            name: "James Nelson"
        },
        {
            img: Assets.Avatar1,
            name: "James Nelson"
        },
        {
            img: Assets.Avatar2,
            name: "Amman Nelson"
        },
    ])

    return (
        <div className="demographics-card">
            <div className="box-wrapper">
                {
                    demographicsData.map((data, index) => (
                        <Link key={index} to="/posts/1">
                            <div className="box border-bottom pb-3 mb-3 d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                    <div className="profile-wrapper me-2">
                                        <img src={data.img} onError={e => e.target.src = Assets.ProfilePlaceholder} alt="" />
                                    </div>
                                    <h6>{data.name}</h6>
                                </div>

                                <div>
                                    <span>Est. Comm: $20.50</span>
                                </div>
                            </div>
                        </Link>
                    ))
                }
            </div>
        </div>
    )
}

export default DemographicsCard