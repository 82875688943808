export const ROLES = {
    brand: 1,
    shopper: 2,
}

export const VALIDATIONS = {
    NAME: 20,
    EMAIL: 30,
    PASSWORD_MIN: 8,
    PASSWORD_MAX: 15,
    PHONE_MIN: 11,
    PHONE_MAX: 15,
    ADDRESS_MAX: 200,
    MESSAGE_MAX: 255,
    DESCRIPTION_MAX: 255,
    FOLDER_TITLE_MIN: 5,
    FOLDER_TITLE_MAX: 15,

    COUNTRY_MAX: 200,
    CITY_MAX: 200,
    STATE_MAX: 55,
    ZIP_CODE: 18,

    VERIFICATION_CODE: 4,
    CARD_NUMBER: 16,
    CVV: 3,
}

export const VALIDATIONS_TEXT = {
    USER_NAME_REQUIRED: "Username is required",
    NAME_REQUIRED: "Name is required",
    FULL_NAME_REQUIRED: "Full name is required",
    BRAND_NAME_REQUIRED: "Brand name is required",
    NAME_MAX: "Max length is " + VALIDATIONS.NAME,

    EMAIL_REQUIRED: "Email is required",
    EMAIL_MAX: "Max length is " + VALIDATIONS.EMAIL,
    EMAIL_FORMAT: "Invalid email format",

    CONFIRM_NEW_PASSWORD_REQUIRED: "Confirm new password is required",
    CURRENT_PASSWORD_REQUIRED: "Current password is required",
    NEW_PASSWORD_REQUIRED: "New password is required",
    PASSWORD_REQUIRED: "Password is required",
    CONFIRM_PASSWORD_REQUIRED: "Confirm password is required",
    PASSWORD_FORMAT: "Password must be at-least 8 characters long with one upper case.",
    PASSWORD_MIN: "Min length is " + VALIDATIONS.PASSWORD_MIN,
    PASSWORD_MAX: "Max length is " + VALIDATIONS.PASSWORD_MAX,

    PHONE_REQUIRED: "Contact number is required",
    PHONE_MIN: "Min length is " + VALIDATIONS.PHONE_MIN,
    PHONE_MAX: "Max length is " + VALIDATIONS.PHONE_MAX,

    ADDRESS_REQUIRED: "Address is required",
    ADDRESS_MAX: "Max length is " + VALIDATIONS.ADDRESS_MAX,

    MESSAGE_REQURIED: "Message is required",
    MESSAGE_MAX: "Max length is " + VALIDATIONS.BIO_MAX,

    DESCRIPTION_REQUIRED: "Description is required",
    DESCRIPTION_MAX: "Max length is " + VALIDATIONS.DESCRIPTION_MAX,

    COUNTRY_MAX: "Max length is " + VALIDATIONS.COUNTRY_MAX,
    CITY_MAX: "Max length is " + VALIDATIONS.CITY_MAX,
    STATE_MAX: "Max length is " + VALIDATIONS.STATE_MAX,
    ZIP_CODE: "Max length is " + VALIDATIONS.ZIP_CODE,

    CARD_NUMBER_REQUIRED: "Card number is required",
    CARD_NUMBER_MIN: "Min length is " + VALIDATIONS.CARD_NUMBER,
    CARD_NUMBER_MAX: "Max length is " + VALIDATIONS.CARD_NUMBER,

    CVV_REQUIRED: "Cvv is requried",
    CVV_MIN: "Min length is " + VALIDATIONS.CVV,
    CVV_MAX: "Max length is " + VALIDATIONS.CVV,

    VERIFICATION_CODE: "Length is " + VALIDATIONS.VERIFICATION_CODE,
    INVALID_FORMAT: "Invalid format",

    FOLDER_TITLE_REQUIRED: "Folder title is required",
    FOLDER_TITLE_MIN: "Min length is " + VALIDATIONS.FOLDER_TITLE_MIN,
    FOLDER_TITLE_MAX: "Max length is " + VALIDATIONS.FOLDER_TITLE_MAX,

    BRAND_CATEGORY_REQUIRED: "Brand category is required",
    ROLE_REQUIRED: "Please select role",
    WEBSITE_URL_REQUIRED: "Website URL is required",
    INVALID_WEBSITE_URL: "Invalid URL",

}

export const REGEX = {
    NAME: /^[a-z0-9]+$/gi,
    PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
    PHONE: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
    CARD_NUMBER: /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/
}

export const SPECIAL_CHARACTER_ARRAY = [
    "~", "`", "!", "@", "#", "$", "%", "^", "&", "*", "(", ")", "-", "_", "+", "=", "{", "}", "[", "]", ";", ":", "'", '"', "\\", "|", "<", ",", ">", ".", "?", "/"
]

export const SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS = [
    "~", "`", "!", "@", "#", "$", "%", "^", "&", "*", "(", ")", "_", "+", "=", "{", "}", "[", "]", ";", ":", "'", '"', "\\", "|", "<", ">", ".", "?", "/"
]

export const CHARACTER_ARRAY_FOR_NUMBER_INPUT = [
    "e", "E", "-", "+", "."
]

export const POPUP_TYPE = {
    folder: "1",
    filter: "2",
    month_filter: "3",
}