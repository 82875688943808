import React, { useState } from 'react'
import CreatePostBox from "../components/dashboard/CreatePostBox"
import { Container, Row, Col } from "react-bootstrap"
import Assets from '../constants/images'
import MassonaryCard from "../components/dashboard/MassonaryCard"

const HomePage = () => {
    // const [posts, setPosts] = useState([])
    const [data, setData] = useState([
        {
            user_img: Assets.Avatar1,
            user_id: "1",
            name: "Elizabeth Thomas",
            id: "1",
            img: Assets.PostImage10,
            heart: true,
            wishlist: true
        },
        {
            user_img: Assets.Avatar2,
            user_id: "2",
            name: "Elizabeth Thomas",
            id: "2",
            img: Assets.PostImage1,
            heart: true,
            wishlist: false
        },
        {
            user_img: Assets.Avatar3,
            user_id: "3",
            name: "Elizabeth Thomas",
            id: "3",
            img: Assets.PostImage2,
            heart: false,
            wishlist: false
        },
        {
            user_img: Assets.Avatar4,
            user_id: "4",
            name: "Elizabeth Thomas",
            id: "4",
            img: Assets.PostImage18,
            heart: true,
            wishlist: true
        },
        {
            user_img: Assets.Avatar5,
            user_id: "5",
            name: "Elizabeth Thomas",
            id: "5",
            img: Assets.PostImage3,
            heart: true,
            wishlist: false
        },
        {
            user_img: Assets.Avatar6,
            user_id: "6",
            name: "Elizabeth Thomas",
            id: "6",
            img: Assets.PostImage11,
            heart: true,
            wishlist: true
        },
        {
            user_img: Assets.Avatar1,
            user_id: "7",
            name: "Elizabeth Thomas",
            id: "7",
            img: Assets.PostImage4,
            heart: false,
            wishlist: false
        },
        {
            user_img: Assets.Avatar3,
            user_id: "9",
            name: "Elizabeth Thomas",
            id: "8",
            img: Assets.PostImage5,
            heart: true,
            wishlist: false
        },
        {
            user_img: Assets.Avatar4,
            user_id: "10",
            name: "Elizabeth Thomas",
            id: "9",
            img: Assets.PostImage6,
            heart: false,
            wishlist: true
        },
        {
            user_img: Assets.Avatar5,
            user_id: "11",
            name: "Elizabeth Thomas",
            id: "10",
            img: Assets.PostImage15,
            heart: false,
            wishlist: false
        },
        {
            user_img: Assets.Avatar6,
            user_id: "12",
            name: "Elizabeth Thomas",
            id: "11",
            img: Assets.PostImage7,
            heart: true,
            wishlist: true
        },
        {
            user_img: Assets.Avatar1,
            user_id: "13",
            name: "Elizabeth Thomas",
            id: "12",
            img: Assets.PostImage13,
            heart: true,
            wishlist: true
        },
        {
            user_img: Assets.Avatar2,
            user_id: "14",
            name: "Elizabeth Thomas",
            id: "13",
            img: Assets.PostImage26,
            heart: true,
            wishlist: true
        },
        {
            user_img: Assets.Avatar1,
            user_id: "15",
            name: "Elizabeth Thomas",
            id: "1",
            img: Assets.PostImage10,
            heart: true,
            wishlist: true
        },
        {
            user_img: Assets.Avatar2,
            user_id: "16",
            name: "Elizabeth Thomas",
            id: "12",
            img: Assets.PostImage7,
            heart: true,
            wishlist: true
        },
    ]);

    return (
        <div className='pages home-page'>
            {
                data.length === 0 ?
                    <div className='d-flex align-items-center justify-content-center h-100'>
                        <CreatePostBox />
                    </div>
                    :
                    <div className='py-3'>
                        <Container fluid >
                            <Row>
                                <Col xs={12}>
                                    <div className='massonary-grid'>
                                        <MassonaryCard link1="user" link2="post" data={data} setData={setData} />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
            }
        </div>
    )
}

export default HomePage