import React from 'react'
import { Form } from "react-bootstrap"
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link } from 'react-router-dom'
import Assets from '../../constants/images'

const WishlistImageCard = ({ link, data }) => {
    return (
        <div className='wishlist-img-card'>
            <Form.Check
                type="checkbox"
                label={
                    <Link className='w-100' to={`${link}/1`}>
                        <img className='img-fluid' src={data.img} onError={e => e.target.src = Assets.PostImagePlaceholder} alt="" />
                    </Link>
                }
            />
        </div>
    )
}

export default WishlistImageCard