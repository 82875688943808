import React from 'react'
import { Modal, Button } from "react-bootstrap"
import { NavLink, Link } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import Assets from '../../constants/images'
import { success } from '../../constants/msg'

const BlockUserPopup = ({ blockUserPopup, setBlockUserPopup }) => {
    const blockUser = () => {
        success("User has been blocked")
        setBlockUserPopup(false)
    }

    return (
        <>
            <ToastContainer />
            <Modal
                className='theme-popup block-alert-popup'
                show={blockUserPopup}
                onHide={() => setBlockUserPopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="wrapper">
                        <div className='text-center mb-30 pb-30 border-bottom'>
                            <h3 className='w-100 d-inline-block'>BLOCK USER</h3>
                        </div>

                        <div className='content-container text-center'>
                            <div>
                                <p>
                                    Are you sure, that you want to block <br />
                                    this user?
                                </p>
                            </div>

                            <div className="mt-5">
                                <Button className="w-100 btn-solid mb-2" onClick={() => blockUser()}>BLOCK</Button>
                                <Button className="w-100 btn-transparent" onClick={() => setBlockUserPopup(false)}>CANCEL</Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default BlockUserPopup