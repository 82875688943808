import React, { useState } from 'react'
import { Button, FloatingLabel, Form } from 'react-bootstrap'
import Assets from '../../constants/images'
import { FaUserAlt, FaMapMarkerAlt } from "react-icons/fa"
import { MdEmail } from "react-icons/md"
import { AiFillLock } from "react-icons/ai"
import { VscEye } from "react-icons/vsc"
import { NavLink, useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { SPECIAL_CHARACTER_ARRAY, VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'
import { ToastContainer } from 'react-toastify';
import { success } from '../../constants/msg';
import { useEffect } from 'react'
import { BrandFill, ClockFill, GlobeFill, LocationFill, MessageIconFill, UserFill } from '../../constants/svgs'
import moment from 'moment'
import BusinessHourPopup from '../popup/BusinessHourPopup'
import Select from 'react-select';

const BrandEditProfileForm = () => {
    const navigate = useNavigate()
    const [businessHoursPopup, setBusinessHoursPopup] = useState(false);
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [timeError, setTimeError] = useState(false)
    const [options, setOptions] = useState([
        { value: '1', label: 'Category 1' },
        { value: '2', label: 'Category 2' },
        { value: '3', label: 'Category 3' },
    ])

    const [file, setFile] = useState({
        file: null,
        file_url: null
    })

    const { register, handleSubmit, reset, watch, setValue, control, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const isError = (startTime, endTime) => {
        // convert start and end time into timestamp than compare
        // also compare that the start and end time should not be empty
        return ((moment(startTime).format("X") >= moment(endTime).format("X")) || (!startTime || !endTime))
    }

    const onSubmit = () => {
        // convert start and end time into timestamp than compare
        // also compare that the start and end time should not be empty

        if (((moment(startTime).format("X") >= moment(endTime).format("X")) || (!startTime || !endTime))) {
            setTimeError(true)  // if error goes here
        }
        else {
            success("Profile updated successfully")
            setTimeout(() => {
                navigate("/my-profile")
            }, 1500)
        }
    }

    const uploadImage = (e) => {
        setFile({
            file: e.target.files[0],
            file_url: URL.createObjectURL(e.target.files[0])
        })
    }

    useEffect(() => {
        reset({
            username: "Anny Kings",
            brand_name: "Anny Kings",
            address: "Toronto, Canada",
            email: "annykings@gmail.com",
            website_url: "https://link-front.theservtech.com/signup",
            brand_category:options[0],
        })

        setStartTime(new Date())
        setEndTime(new Date())
    }, [])

    return (
        <>
            <ToastContainer />
            <BusinessHourPopup setTimeError={setTimeError} businessHoursPopup={businessHoursPopup} setBusinessHoursPopup={setBusinessHoursPopup} startTime={startTime} setStartTime={setStartTime} endTime={endTime} setEndTime={setEndTime} />

            <div className="auth-card edit-profile-form">
                <div className='text-center d-flex align-items-center mb-30 pb-30 border-bottom'>
                    <h3 className='w-100 d-inline-block'>EDIT PROFILE</h3>
                </div>

                <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className='box text-center mt-4 mb-2'>
                        <label className="post-profile-wrapper" htmlFor="post-profile">
                            <input onChange={e => uploadImage(e)} className='d-none' type="file" accept="image/*" id="post-profile" />
                            <img src={file.file_url ? file.file_url : Assets.BrandAvatarLarge2} onError={e => e.target.src = Assets.ProfilePlaceholder} alt="" />
                        </label>
                    </div>
                    <span className="d-block text-center">Upload/capture profile picture</span>
                    <div className='mt-30 px-40'>
                        <FloatingLabel controlId="username" label="User Name *">
                            <Form.Control
                                onKeyDown={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key) && e.preventDefault()) && e.preventDefault()}
                                maxLength={VALIDATIONS.NAME}
                                type="text"
                                placeholder="User Name *"
                                {...register("username",
                                    {
                                        maxLength: {
                                            value: VALIDATIONS.NAME,
                                            message: VALIDATIONS_TEXT.NAME_MAX
                                        },
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.USER_NAME_REQUIRED
                                        },
                                    })
                                }
                            />
                            <div className="icon-wrapper">
                                <UserFill className='icon' />
                            </div>
                        </FloatingLabel>
                        {errors.username && <small className='text-start d-block text-danger mt-1'>{errors.username.message}</small>}

                        <FloatingLabel className="mt-20" controlId="name" label="Brand Name *">
                            <Form.Control
                                onKeyDown={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key) && e.preventDefault()) && e.preventDefault()}
                                maxLength={VALIDATIONS.NAME}
                                type="text"
                                placeholder="Brand name *"
                                {...register("brand_name",
                                    {
                                        maxLength: {
                                            value: VALIDATIONS.NAME,
                                            message: VALIDATIONS_TEXT.NAME_MAX
                                        },
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.BRAND_NAME_REQUIRED
                                        },
                                    })
                                }
                            />
                            <div className="icon-wrapper">
                                <UserFill className='icon' />
                            </div>
                        </FloatingLabel>
                        {errors.brand_name && <small className='text-start d-block text-danger mt-1'>{errors.brand_name.message}</small>}

                        <div className="react-select">
                            <div className="icon-wrapper">
                                <BrandFill className='icon' />
                            </div>

                            <Controller
                                control={control}
                                name="brand_category"
                                rules={{
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.BRAND_CATEGORY_REQUIRED,
                                    },
                                }}
                                render={({field})=>(
                                    <Select
                                        {...field}
                                        placeholder="Brand Category *"
                                        className="mt-20 form-control"
                                        classNamePrefix="react-select-container"
                                        isSearchable={false}
                                        options={options}
                                    />
                                )}
                            />
                        </div>
                        {errors.brand_category && <small className='text-start d-block text-danger mt-1'>{errors.brand_category.message}</small>}

                        <FloatingLabel className="mt-20" controlId="businessHours" label="Business Hours *" onClick={() => setBusinessHoursPopup(true)}>
                            <Form.Control
                                value={(startTime && endTime) && `${moment(startTime).format("hh:mm a")} - ${moment(endTime).format("hh:mm a")}`}
                                type="text"
                                placeholder="Business Hours *"
                                readOnly
                            />
                            <div className="icon-wrapper">
                                <ClockFill className='icon' />
                            </div>
                        </FloatingLabel>
                        {timeError && <small className='text-start d-block text-danger mt-1'>{(!startTime || !endTime) ? "Business hours is required" : "Opening time should be less than closing time"}</small>}

                        <FloatingLabel className="mt-20" controlId="websiteUrl" label="Website URL *">
                            <Form.Control
                                type="text"
                                placeholder="Website URL *"
                                {...register("website_url",
                                    {
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.WEBSITE_URL_REQUIRED
                                        },
                                        pattern: {
                                            value: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)/,
                                            message: VALIDATIONS_TEXT.INVALID_WEBSITE_URL
                                        }
                                    })
                                }
                            />
                            <div className="icon-wrapper">
                                <GlobeFill className='icon' />
                            </div>
                        </FloatingLabel>
                        {errors.website_url && <small className='text-start d-block text-danger mt-1'>{errors.website_url.message}</small>}

                        <FloatingLabel className="mt-20" controlId="address" label="Address *">
                            <Form.Control
                                maxLength={VALIDATIONS.ADDRESS_MAX}
                                type="text"
                                placeholder="Address *"
                                {...register("address",
                                    {
                                        maxLength: {
                                            value: VALIDATIONS.ADDRESS_MAX,
                                            message: VALIDATIONS_TEXT.ADDRESS_MAX
                                        },
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.ADDRESS_REQUIRED
                                        },
                                    })
                                }
                            />
                            <div className="icon-wrapper">
                                <LocationFill className='icon' />
                            </div>
                        </FloatingLabel>
                        {errors.address && <small className='text-start d-block text-danger mt-1'>{errors.address.message}</small>}


                        <FloatingLabel className="mt-20" controlId="email" label="Email address *">
                            <Form.Control
                                maxLength={VALIDATIONS.EMAIL}
                                type="email"
                                readOnly
                                placeholder="Email address *"
                                {...register("email",
                                    {
                                        maxLength: {
                                            value: VALIDATIONS.EMAIL,
                                            message: VALIDATIONS_TEXT.EMAIL_MAX
                                        },
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.EMAIL_REQUIRED
                                        },
                                        pattern: {
                                            value: /\S+@\S+\.\S+/,
                                            message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                        }
                                    })
                                }
                            />
                            <div className="icon-wrapper">
                                <MessageIconFill className='icon' />
                            </div>
                        </FloatingLabel>
                        {errors.email && <small className='text-start d-block text-danger mt-1'>{errors.email.message}</small>}


                        <Button type="submit" className='btn-solid my-5'>UPDATE PROFILE</Button>
                    </div>
                </Form>
            </div>
        </>
    )
}

export default BrandEditProfileForm