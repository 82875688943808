import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import OtpInput from "react-otp-input";
import { useNavigate } from 'react-router-dom';
import { success } from '../../constants/msg';
import { ToastContainer } from 'react-toastify';

const PasswordVerificationForm = () => {
    const [counter, setCounter] = useState(30);
    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate()

    const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm({
        mode: "onChange"
    });

    useEffect(() => {
        let timer = counter > 0 && setInterval(() => {
            setCounter(counter - 1)
        }, 1000)

        return () => {
            clearInterval(timer)
        }
    }, [counter])

    const onSubmit = () => {
        if (otp.length === 0) {
            setOtpError(true)
            setErrorMessage("Please Enter OTP code")
        }
        else if (otp.length > 0 && otp.length < 4) {
            setOtpError(true)
            setErrorMessage("OTP code is incomplete")
        }
        else {
            // Logic for sending OTP CODE goes here
            success("OTP verified")
            setTimeout(() => {
                navigate("/reset-password")
            }, 1500);
        }
    }

    const handleOtpChange = (otp) => {
        setOtp(otp)
        if (otp.length === 0) {
            setOtpError(true)
            setErrorMessage("Please Enter OTP code")
        }
        else if (otp.length > 0 && otp.length < 4) {
            setOtpError(true)
            setErrorMessage("OTP code is incomplete")
        }
        else {
            setOtpError(false)
        }
    }

    const resendCode = () => {
        setCounter(30)
        // Logic for resending OTP CODE goes here  
    }

    return (
        <div className='auth-card password-verification-form'>
            <ToastContainer/>
            <div>
                <h3 className='pb-30 border-bottom mb-30'>PASSWORD VERIFICATION</h3>
                <p>
                    Enter the 4 digit code you received <br />
                    on Email address.
                </p>
            </div>

            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className='mt-40'>
                    <OtpInput
                        className="otp-input"
                        value={otp}
                        onChange={(otp) => handleOtpChange(otp)}
                        numInputs={4}
                        isInputNum={true}
                        placeholder="----"
                        hasErrored={true}
                        focusStyle="focus"
                    />
                    {otpError && <small className='text-start d-block text-danger mt-1'>{errorMessage}</small>}

                    <Button type="submit" className='mt-40 mb-30 btn-solid'>VERIFY PASSWORD</Button>
                    {
                        counter > 0 ?
                            <span className='d-inline-block mb-20'>Resend in: &nbsp; <b className='timer'> 00:{counter < 10 && '0'}{counter}</b></span>
                            :
                            <span className='d-inline-block mb-20' onClick={() => resendCode()}><b className='timer cursor'>Resend Code</b></span>
                    }
                </div>
            </Form>
        </div>
    )
}

export default PasswordVerificationForm
