import axios from 'axios'
import { constant } from '../utils/constants'

const api = axios.create({
    baseURL: constant.BASE_URL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
    }
})

export default api;