import React, { useContext, useState } from 'react';
import { Modal, Button, Form, Col, Row } from "react-bootstrap"
import { useForm } from 'react-hook-form'
import { NavLink, Link, useNavigate } from 'react-router-dom'
import Assets from '../../constants/images'
import { Times } from '../../constants/svgs'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { success } from '../../constants/msg'
import { ToastContainer } from 'react-toastify';
import { AddProductContext } from '../../App';

const SchedulePostPopup = ({ schedulePostPopup, setSchedulePostPopup }) => {
    const navigate = useNavigate()
    const [addProduct, setAddProduct] = useContext(AddProductContext)
    const [value, onChange] = useState(new Date());
    const hours = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"]
    const minutes = ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"]

    const schedulePost = () => {
        success("Post scheduled successfully")
        setSchedulePostPopup(false)
        setAddProduct([])
        navigate("/home")
    }

    return (
        <>
            <ToastContainer />
            <Modal
                className='theme-popup calendar-popup'
                show={schedulePostPopup}
                onHide={() => setSchedulePostPopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="wrapper">
                        <div className='d-flex text-center mb-30 pb-30 border-bottom'>
                            <h3 className='w-100 d-inline-block'>SCHEDULE</h3>
                            <div className="text-end times-icon-wrapper" onClick={() => setSchedulePostPopup(false)}>
                                <Times />
                            </div>
                        </div>

                        <div className="calendar-wrapper">
                            <Calendar onChange={onChange} value={value} />
                            <div className="time-picker-wrapper border-top mt-30 pt-30">
                                <div className='d-flex align-items-center justify-content-between'>
                                    <div>
                                        <h3>TIME</h3>
                                    </div>

                                    <div className='d-flex align-items-center'>
                                        <div className='time-box d-flex align-items-center mx-2'>
                                            <Form.Select className="ps-1" onChange={e => console.log(e.target.value)}>
                                                {
                                                    hours.map((hour, index) => (
                                                        <option kye={index} value={hour}>{hour}</option>
                                                    ))
                                                }
                                            </Form.Select>
                                            :
                                            <Form.Select className="ps-2 pe-1">
                                                {
                                                    minutes.map((minute, index) => (
                                                        <option kye={index} value={minute}>{minute}</option>
                                                    ))
                                                }
                                            </Form.Select>
                                        </div>

                                        <div className="ampm-box">
                                            <label htmlFor="am" className="me-1">
                                                AM
                                                <input checked className="d-none" type="radio" id="am" name="ampm" />
                                            </label>

                                            <label htmlFor="pm">
                                                PM
                                                <input className="d-none" type="radio" id="pm" name="ampm" />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Button className="mt-30 btn-solid w-100" onClick={() => schedulePost()}>SCHEDULE POST</Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SchedulePostPopup
