import React, { useEffect, useContext } from 'react'
import { Routes, Route, useNavigate, useLocation } from "react-router-dom"
import PublicRoute from './PublicRoute'
import ProtectedRoute from './ProtectedRoute'
import AuthLayout from '../layouts/authentication/AuthLayout'
import SplashForm from '../components/authentication/SplashForm'
import SelectRoleForm from '../components/authentication/SelectRoleForm'
import SignupShopperForm from '../components/authentication/SignupShopperForm'
import SignupBrandForm from '../components/authentication/SignupBrandForm'
import SigninForm from '../components/authentication/SigninForm'
import ForgotPasswordForm from '../components/authentication/ForgotPasswordForm'
import ResetPasswordForm from '../components/authentication/ResetPasswordForm'
import AccountVerificationForm from '../components/authentication/AccountVerificationForm'
import PasswordVerificationForm from '../components/authentication/PasswordVerificationForm'
import DashboardLayout from '../layouts/dashboard/DashboardLayout'
import HomePage from '../pages/HomePage'
import CreatePostPage from '../pages/CreatePostPage'
import AddPostPage from '../pages/AddPostPage'
import PostDetailPage from '../pages/PostDetailPage'
import ViewProfilePage from '../pages/ViewProfilePage'
import EditProfilePage from '../pages/EditProfilePage'
import CreatePostForm from '../components/forms/CreatePostForm'
import BlockedUserPage from '../pages/BlockedUserPage'
import ChangePasswordPage from '../pages/ChangePasswordPage'
import SettingSidebar from '../components/dashboard/SettingSidebar'
import TermsAndConditionsPage from '../pages/TermsAndConditionsPage'
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage'
import EmptyHomePage from '../pages/EmptyHomePage'
import BrandPage from '../pages/BrandPage'
import BrandDetailPage from '../pages/BrandDetailPage'
import AboutPage from '../pages/AboutPage'
import ContactPage from '../pages/ContactPage'
import ViewMyProfileCard from '../components/dashboard/ViewMyProfileCard'
import ViewProfileCard from '../components/dashboard/ViewProfileCard'
import StatisticsAndAnalyticsPage from '../pages/StatisticsAndAnalyticsPage'
import RecentActivityPage from '../pages/RecentActivityPage'
import MyFavouritesPage from '../pages/MyFavouritesPage'
import PostDetailStatsPage from '../pages/PostDetailStatsPage'
import EditPostPage from '../pages/EditPostPage'
import ViewMyProfilePage from '../pages/ViewMyProfilePage'
import BrandPostDetailPage from '../pages/BrandPostDetailPage'
import MyFavouritesPostPage from '../pages/MyFavouritesPostPage'
import RecentActivityPostPage from '../pages/RecentActivityPostPage'
import SchedulePostPage from '../pages/SchedulePostPage'
import SchedulePostDetailPage from '../pages/SchedulePostDetailPage'
import PostListPage from '../pages/PostListPage'
import BrandPostListPage from '../pages/BrandPostListPage'
import MyProfilePostListPage from '../pages/MyProfilePostListPage'
import MyProfilePostDetailPage from '../pages/MyProfilePostDetailPage'
import { ROLES } from "../constants/app-constants"
import { UserRole } from "../App"
import EditPostForm from '../components/forms/EditPostForm'
import MyProfileAddProductPage from '../pages/MyProfileAddProductPage'
import RollUpCard from '../components/dashboard/RollUpCard'
import ErrorPage from '../pages/ErrorPage'

const Navigation = () => {
    const [userRole, setUserRole] = useContext(UserRole)
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [navigate, location])

    const signUp = {
        [ROLES.brand]: <SignupBrandForm />,
        [ROLES.shopper]: <SignupShopperForm />,
    }

    return (
        <Routes>
            {/* ************** AUTHENTICATION ROUTES ************** */}
            <Route element={<PublicRoute />}>
                <Route path='/' element={<AuthLayout children={<SplashForm />} />}></Route>
            </Route>

            <Route element={<PublicRoute />}>
                <Route path='/select-role' element={<AuthLayout children={<SelectRoleForm />} />}></Route>
            </Route>

            <Route element={<PublicRoute />}>
                <Route path='/signup' element={<AuthLayout children={signUp[userRole]} />}></Route>
            </Route>

            <Route element={<PublicRoute />}>
                <Route path='/signin' element={<AuthLayout children={<SigninForm />} />}></Route>
            </Route>

            <Route element={<PublicRoute />}>
                <Route path='/forgot-password' element={<AuthLayout children={<ForgotPasswordForm />} />}></Route>
            </Route>

            <Route element={<PublicRoute />}>
                <Route path='/reset-password' element={<AuthLayout children={<ResetPasswordForm />} />}></Route>
            </Route>

            <Route element={<PublicRoute />}>
                <Route path='/account-verification' element={<AuthLayout children={<AccountVerificationForm />} />}></Route>
            </Route>

            <Route element={<PublicRoute />}>
                <Route path='/password-verification' element={<AuthLayout children={<PasswordVerificationForm />} />}></Route>
            </Route>

            {/* ************** DASHBOARD ROUTES ************** */}

            {/* **** REMOVE THIS ROUTE JUST FOR ALPHA PURPOSE **** */}
            <Route path='/empty-home' element={<DashboardLayout children={<EmptyHomePage />} />}></Route>

            <Route element={<ProtectedRoute />}>
                <Route path='/home' element={<DashboardLayout children={<HomePage />} />}></Route>
            </Route>


            <Route element={<ProtectedRoute />}>
                <Route path='/create-post' element={<DashboardLayout children={<CreatePostPage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path='/edit-post' element={<DashboardLayout children={<EditPostPage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path='/add-post' element={<DashboardLayout children={<AddPostPage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path='/statistics-and-analytics' element={<DashboardLayout children={<StatisticsAndAnalyticsPage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path='/statistics-and-analytics/post/:slug' element={<DashboardLayout children={<PostDetailStatsPage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path='/post/:slug' element={<DashboardLayout children={<PostDetailPage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path='/posts/:slug' element={<DashboardLayout children={<PostListPage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path='/user/:slug' element={<DashboardLayout children={<ViewProfilePage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path='/edit-profile' element={<DashboardLayout children={<EditProfilePage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path='/my-profile' element={<DashboardLayout children={<ViewMyProfilePage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path='/my-profile/posts/:slug' element={<DashboardLayout children={<MyProfilePostListPage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path='/my-profile/post/:slug' element={<DashboardLayout children={<MyProfilePostDetailPage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path='/my-profile/add-product' element={<DashboardLayout children={<MyProfileAddProductPage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path='/brands' element={<DashboardLayout children={<BrandPage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>

                <Route path='/brand/:slug' element={<DashboardLayout children={<BrandDetailPage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path='/brand/post/:slug' element={<DashboardLayout children={<BrandPostDetailPage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path='/brand/posts/:slug' element={<DashboardLayout children={<BrandPostListPage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path='/about' element={<DashboardLayout children={<AboutPage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path='/contact' element={<DashboardLayout children={<ContactPage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path='/my-favourites' element={<DashboardLayout children={<MyFavouritesPage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path='/my-favourites/post/:slug' element={<DashboardLayout children={<MyFavouritesPostPage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path='/recent-activity' element={<DashboardLayout children={<RecentActivityPage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path='/recent-activity/post/:slug' element={<DashboardLayout children={<RecentActivityPostPage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path='/schedule-post' element={<DashboardLayout children={<SchedulePostPage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path='/schedule-post/:slug' element={<DashboardLayout children={<SchedulePostDetailPage />} />}></Route>
            </Route>


            <Route element={<ProtectedRoute />}>
                <Route path='/blocked-users' element={<DashboardLayout children={<BlockedUserPage />} />}></Route>
            </Route>


            <Route element={<ProtectedRoute />}>
                <Route path='/settings/change-password' element={<DashboardLayout children={<ChangePasswordPage children={<SettingSidebar />} />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path='/settings/terms-and-conditions' element={<DashboardLayout children={<TermsAndConditionsPage children={<SettingSidebar />} />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path='/settings/privacy-policy' element={<DashboardLayout children={<PrivacyPolicyPage children={<SettingSidebar />} />} />}></Route>
            </Route>

            {/* ******* NOTE: PLACE ALL ROUTES BEFORE ERROR ROUTE ******* */}
            <Route path="*" element={<ErrorPage />} />
        </Routes>
    )
}

export default Navigation