import React, { useState } from 'react'
import { Modal, Button, Form, Col, Row } from "react-bootstrap"
import { useForm } from 'react-hook-form'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { NavLink, Link } from 'react-router-dom'
import Assets from '../../constants/images'
import { Times } from '../../constants/svgs'

const MessagePopup = ({ messagePopup, setMessagePopup }) => {
    const [searchText, setSearchText] = useState("")
    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const onSubmit = () => {
        setMessagePopup(false)
    }

    const [messages, setMessages] = useState([
        {
            img: Assets.Avatar12,
            name: "Patrick Nelson",
            time: "12min",
            status: "0"
        },
        {
            img: Assets.Avatar11,
            name: "George Nelson",
            time: "12min",
            status: "0"
        },
        {
            img: Assets.Avatar10,
            name: "Amanda Parker",
            time: "12min",
            status: "0"
        },
        {
            img: Assets.Avatar8,
            name: "Haris Roundback",
            time: "12min",
            status: "0"
        },
        {
            img: Assets.Avatar9,
            name: "James Colin",
            time: "12min",
            status: "1"
        },
        {
            img: Assets.Avatar7,
            name: "Taric James",
            time: "12min",
            status: "1"
        },
        {
            img: Assets.Avatar10,
            name: "John Nelson",
            time: "12min",
            status: "1"
        },
    ])

    const handleSearch = (e) => {
        setSearchText(e.target.value)
    }

    return (
        <Modal
            className='theme-popup message-popup'
            show={messagePopup}
            onHide={() => setMessagePopup(false)}
            size="md"
            centered
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Body>
                <div className="wrapper">
                    <div className='d-flex text-center mb-20 pb-30 border-bottom'>
                        <h3 className='w-100 d-inline-block'>MESSAGES <span>(4)</span></h3>
                        <div className="text-end times-icon-wrapper" onClick={() => setMessagePopup(false)}>
                            <Times />
                        </div>
                    </div>

                    <div className='notification-container'>
                        <div className='search-wrapper'>
                            <Form.Control
                                value={searchText}
                                onChange={(e) => handleSearch(e)}
                                type="text"
                                placeholder="Search"
                                className="search-input"
                                aria-label="Search"
                                onKeyDown={(e) => e.keyCode === 13 && setSearchText("")}
                            />
                        </div>
                    </div>

                    <div className="message-wrapper">
                        {
                            messages.map((data, index) => (
                                <Link key={index} to="#">
                                    <div className="box border-bottom mt-3 pb-3">
                                        <div className="d-flex align-items-center">
                                            <div className="profile-wrapper me-2">
                                                <img src={data.img} onError={e => e.target.src = Assets.ProfilePlaceholder} alt="" />
                                            </div>

                                            <div className="custom-position text-end w-100">
                                                <small>12min</small>
                                                <div className="text-start">
                                                    <h6 className=''>{data.name}</h6>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <p className={`${data.status === "1" && "text-grey"}`}>Wanna go outside someday?</p>
                                                        <div className="count">2</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            ))
                        }
                    </div>

                    <div className="view-all-box text-center pt-3">
                        <Link to="#">VIEW ALL MESSAGES</Link>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default MessagePopup
