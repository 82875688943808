import React from 'react'
import { Modal, Button, Form, Col, Row } from "react-bootstrap"
import { useForm } from 'react-hook-form'
import { NavLink, Link } from 'react-router-dom'
import Assets from '../../constants/images'
import { Times } from '../../constants/svgs'

const FilterPopup = ({ filterPopup, setFilterPopup }) => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const onSubmit = () => {
        setFilterPopup(false)
    }

    return (
        <Modal
            className='theme-popup filter-popup'
            show={filterPopup}
            onHide={() => setFilterPopup(false)}
            size="md"
            centered
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Body>
                <div className="wrapper">
                    <div className='d-flex align-items-center text-center mb-30 pb-30 border-bottom'>
                        <h3 className='w-100 d-inline-block'>FILTER BY CATEGORIES</h3>
                        <div className="text-end times-icon-wrapper" onClick={() => setFilterPopup(false)}>
                            <Times />
                        </div>
                    </div>

                    <div>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <div className='content-container'>
                                <Row>
                                    <Col xs={4} className="mb-2">
                                        <label htmlFor="Clothing">
                                            Clothing
                                            <input checked className="d-none" type="checkbox" id="Clothing" />
                                        </label>
                                    </Col>

                                    <Col xs={4} className="mb-2">
                                        <label htmlFor="Footwear">
                                            Footwear
                                            <input className="d-none" type="checkbox" id="Footwear" />
                                        </label>
                                    </Col>

                                    <Col xs={4} className="mb-2">
                                        <label htmlFor="Lifestyle">
                                            Lifestyle
                                            <input checked className="d-none" type="checkbox" id="Lifestyle" />
                                        </label>
                                    </Col>

                                    <Col xs={4} className="mb-2">
                                        <label htmlFor="Home&Decor">
                                            Home & Decor
                                            <input className="d-none" type="checkbox" id="Home&Decor" />
                                        </label>
                                    </Col>

                                    <Col xs={4} className="mb-2">
                                        <label htmlFor="Sports">
                                            Sports
                                            <input className="d-none" type="checkbox" id="Sports" />
                                        </label>
                                    </Col>

                                    <Col xs={4} className="mb-2">
                                        <label htmlFor="Kids">
                                            Kids
                                            <input className="d-none" type="checkbox" id="Kids" />
                                        </label>
                                    </Col>

                                    <Col xs={4} className="mb-2">
                                        <label htmlFor="Male">
                                            Male
                                            <input className="d-none" type="radio" name="gender" id="Male" />
                                        </label>
                                    </Col>

                                    <Col xs={4} className="mb-2">
                                        <label htmlFor="Female">
                                            Female
                                            <input className="d-none" type="radio" name="gender" id="Female" />
                                        </label>
                                    </Col>

                                    <Col xs={4} className="mb-2">
                                        <label htmlFor="Unisex">
                                            Unisex
                                            <input checked className="d-none" type="radio" name="gender" id="Unisex" />
                                        </label>
                                    </Col>
                                </Row>
                                <div className="mt-30">
                                    <Button type="submit" className="w-100 btn-solid">FILTER NOW</Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default FilterPopup