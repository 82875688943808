import React from 'react'
import { DollarRounded } from '../../constants/svgs'

const StatsCard = () => {
    return (
        <div className='stats-card'>
            <div className="wrapper p-4">
                <div className="box d-flex justify-content-center align-items-center p-3 ">
                    <div className="icon-wrapper d-flex align-items-center justify-content-center me-3">
                        <DollarRounded/>
                    </div>
                    <div>
                        <span>Total amount of <br /> commission earned</span>
                        <h1>$565</h1>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StatsCard