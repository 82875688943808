import React, { useState, useEffect, useContext } from 'react'
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import AddedPostCard from '../components/dashboard/AddedPostCard'
import ViewProfileCard from '../components/dashboard/ViewProfileCard'
import WishlistImageCard from '../components/dashboard/WishlistImageCard'
import Assets from '../constants/images'
import DashboardBreadCrumb from '../layouts/dashboard/DashboardBreadCrumb'

const ViewProfilePage = ({ children }) => {
    const location = useLocation()
    const [wishList, setWishList] = useState([
        {
            img: Assets.PostImage1,
            isChecked: false
        },
        {
            img: Assets.PostImage5,
            isChecked: false
        },
        {
            img: Assets.PostImage11,
            isChecked: false
        },
        {
            img: Assets.PostImage12,
            isChecked: false
        },
        {
            img: Assets.PostImage13,
            isChecked: false
        },
        {
            img: Assets.PostImage18,
            isChecked: false
        },
        {
            img: Assets.PostImage19,
            isChecked: false
        },
        {
            img: Assets.PostImage20,
            isChecked: false
        },
        {
            img: Assets.PostImage21,
            isChecked: false
        },
        {
            img: Assets.PostImage22,
            isChecked: false
        },
        {
            img: Assets.PostImage23,
            isChecked: false
        },
        {
            img: Assets.PostImage24,
            isChecked: false
        },
    ])

    const [data, setData] = useState({
        img: Assets.AvatarLarge1,
        name:"Anny Kings",
        location:"Toronto, Canada",
        email:"elizabeth23@gmail.com"
    })

    return (
        <>
            <DashboardBreadCrumb link1="/home" link1Text="Home" link2="#" link2Text="Elizebath Thomas" link3="" link3Text="" link4="" link4Text="" />
            <div className='pages view-profile-page'>
                <div className="pb-4">
                    <Container fluid>
                        <Row>
                            <Col xs={12}>
                               <ViewProfileCard data={data} />
                            </Col>

                            <Col xs={12}>
                                <Tabs
                                    defaultActiveKey="Gallery"
                                    id="uncontrolled-tab-example"
                                    className="theme-tabs"
                                >
                                    <Tab eventKey="Gallery" title="Gallery">
                                        <Row>
                                            {
                                                wishList.map((data, index) => (
                                                    <Col key={index} xs={6} sm={4} md={4} lg={3} xl={2} className="mt-4">
                                                        <WishlistImageCard data={data} link="/posts" />
                                                    </Col>
                                                ))
                                            }
                                        </Row>
                                    </Tab>

                                    <Tab eventKey="Collection" title="Collection">
                                        <div className='mt-3'>
                                            <AddedPostCard showSelectedImageBox={false} Card={WishlistImageCard} link="/posts" />
                                        </div>
                                    </Tab>
                                </Tabs>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default ViewProfilePage