import React, { useState } from 'react'
import { Modal, Button, Form, Col, Row } from "react-bootstrap"
import { useForm } from 'react-hook-form'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { NavLink, Link } from 'react-router-dom'
import Assets from '../../constants/images'
import { SearchOutlineLg, Times } from '../../constants/svgs'

const SearchPopup = ({ searchPopup, setSearchPopup }) => {
    const [suggestions, setSuggestions] = useState([
        {
            id: 1,
            name: "Elizabeth Thomas"
        },
        {
            id: 2,
            name: "Handbag"
        },
        {
            id: 3,
            name: "High Heel"
        },
        {
            id: 4,
            name: "Elizabeth Thomas"
        },
        {
            id: 5,
            name: "Handbag"
        },
        {
            id: 6,
            name: "High Heel"
        },
        {
            id: 7,
            name: "Elizabeth Thomas"
        },
        {
            id: 8,
            name: "Handbag"
        },
    ])
    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const onSubmit = () => {
        reset({
            search:""
        })
        // setSearchPopup(false)
    }

    return (
        <Modal
            className='search-popup'
            show={searchPopup}
            onHide={() => setSearchPopup(false)}
            size="lg"
            centered
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Body>
                <div className="search-wrapper">
                    <div className="search-box">
                        <Form className="w-100 h-100" onSubmit={handleSubmit(onSubmit)}>
                            <div className='d-flex align-items-center justify-content-between h-100'>
                                <div className='w-100 h-100'>
                                    <Form.Control
                                        className='w-100'
                                        type="text"
                                        placeholder="Search"
                                        {...register("search")}
                                    />
                                </div>

                                <div className='d-flex align-items-center'>
                                    <div>
                                        <span className="d-block d-sm-none mt-1-5" onClick={() => reset({search:""})}><SearchOutlineLg/></span>
                                        <Button type="submit" className='d-none d-sm-block btn-solid' onClick={() => reset({search:""})}>Search</Button>
                                    </div>

                                    <div className='time-icon-wrapper' onClick={() => reset({search:""}) }>
                                        <Times />
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>

                    <div className='suggestion-box'>
                        <div className='title-div'>
                            <h6>Recent Search</h6>
                            <hr />
                        </div>

                        <div className="suggest-list pt-2">
                            <ul>
                                {
                                    suggestions.map((data, index) => (
                                        <li key={index}>
                                            <Link to="#" className='d-flex align-items-center justify-content-between'>
                                                <h6>{data.name}</h6>
                                                <div className='time-icon-wrapper'>
                                                    <Times />
                                                </div>
                                            </Link>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default SearchPopup
