import React from 'react'
import { Modal, Button, Form } from "react-bootstrap"
import { NavLink, Link, useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import Assets from '../../constants/images'
import { success } from '../../constants/msg'

const DeleteAccountPopup = ({ deleteAccountPopup, setDeleteAccountPopup }) => {
    const navigate = useNavigate();

    const deleteAccount = () => {
        setDeleteAccountPopup(false)
        success("Account deleted successfully")
        setTimeout(() => {
        }, 1500);
    }

    return (
        <>
            <ToastContainer />
            <Modal
                className='theme-popup delete-account-popup'
                show={deleteAccountPopup}
                onHide={() => setDeleteAccountPopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="wrapper">
                        <div className='text-center mb-30 pb-30 border-bottom'>
                            <h3 className='w-100 d-inline-block'>DELETE ALERT</h3>
                        </div>

                        <div className='content-container text-center'>
                            <div>
                                <p>
                                    Are you sure, that you want to delete <br />
                                    your The Link Front account?
                                </p>
                            </div>

                            {/* <div className="mt-20 text-start">
                                <label htmlFor="reason"> Please list any reason why you migh be leaving</label>
                                <Form.Control id="reason" as="textarea" rows={0} placeholder="Write a comment..." />
                            </div> */}

                            <div className="mt-5">
                                <Button className="w-100 btn-solid mb-2" onClick={() => deleteAccount()}>DELETE ACCOUNT</Button>
                                <Button className="w-100 btn-transparent" onClick={() => setDeleteAccountPopup(false)}>CANCEL</Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default DeleteAccountPopup