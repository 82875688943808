import React, { useState } from 'react'
import { Dropdown, Form } from 'react-bootstrap'
import { DotsHorizontal } from '../../constants/svgs'
import Assets from "../../constants/images"
import { ShareOutline, MessageOutline, HeartOutline, HeartFill, WishListOutline, WishListFill } from '../../constants/svgs'
import ShowMore from 'react-show-more';
import { LazyLoadImage } from 'react-lazy-load-image-component'

const PostDetailStatsCard = ({ blockUserPopup, setBlockUserPopup, isCommentClicked, setIsCommentClicked, data, setData }) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [isWishlistChecked, setIsWishlistChecked] = useState(false)
    const [isHeartChecked, setIsHeartChecked] = useState(false)

    const reportPost = () => {

    }

    return (
        <div className='post-detail-card'>
            <div className='text-center border-bottom mb-30 pb-30'>
                <h3>{data.name}</h3>
            </div>

            <div className='d-flex justify-content-between'>
                <div className='d-flex'>
                    <div className="profile-wrapper">
                        <img src={data.profile_image} onError={e => e.target.src = Assets.ProfilePlaceholder} alt="" />
                    </div>

                    <div className="ms-2">
                        <span className="name d-block"><b>{data.name}</b></span>
                        <small className="time">{data.time}</small>
                    </div>
                </div>

                <div>
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic">
                            <DotsHorizontal />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="text-center">
                            <Dropdown.Item className="border-bottom" onClick={() => reportPost()}>Report Post</Dropdown.Item>
                            <Dropdown.Item className="border-bottom" onClick={() => setBlockUserPopup(true)}>Block User</Dropdown.Item>
                            <Dropdown.Item className="">Cancel</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>

            <div className='post-img-wrapper mt-2'>
                <div className='post-img'>
                    <h4 className='mb-3'>{data.caption}</h4>
                    <img src={data.cover_image} onError={e => e.target.src = Assets.GeneralPlaceholder} alt="" />
                </div>

                <div className="mt-20 mb-20">
                    <span className="shop-now">EXACT PRODUCTS</span>
                </div>

                <div className='img-list-view-wrapper'>
                    <div className="list-wrapper">
                        {
                            data.extra_products.map((product, index) => (
                                <div key={index} className="d-flex border-bottom pb-2 mb-3">
                                    <div>
                                        <img src={product.img} onError={e => e.target.src = Assets.GeneralPlaceholder} alt="" />
                                    </div>

                                    <div className="ps-4">
                                        <h4>{product.name}</h4>
                                        <ul>
                                            <li>{product.store}</li>
                                            <li>{`Est. Comm: ${product.est_comm}`}</li>
                                            <li>{`Price: ${product.price}`}</li>
                                        </ul>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div >
    )
}

export default PostDetailStatsCard