import React, { useState, useContext } from 'react'
import { Navbar, Nav, Container, Button, Offcanvas, Form } from "react-bootstrap"
import { Link, NavLink } from 'react-router-dom'
import Assets from "../../constants/images"
import { BsBellFill } from 'react-icons/bs'
import { RiMessage2Fill } from "react-icons/ri"
import { MdAddToPhotos } from "react-icons/md"
import ShopperProfilePopup from "../../components/popup/ShopperProfilePopup"
import BrandProfilePopup from "../../components/popup/BrandProfilePopup"
import NotificationPopup from "../../components/popup/NotificationPopup"
import MessagePopup from "../../components/popup/MessagePopup"
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { BellFill, DropDownArrow, MessageFillGreen, MessageIconFill, SearchOutline } from '../../constants/svgs'
import { UserRole } from '../../App'
import { ROLES } from '../../constants/app-constants'
import SearchPopup from '../../components/popup/SearchPopup'

const DashboardNavbar = () => {
    const [searchPopup, setSearchPopup] = useState(false)
    const [profilePopup, setProfilePopup] = useState(false)
    const [notificationPopup, setNotificationPopup] = useState(false)
    const [messagePopup, setMessagePopup] = useState(false)
    const [menuOpen, setMenuOpen] = useState(false)
    const [userRole, setUserRole] = useContext(UserRole)

    const userProfilePopup = {
        [ROLES.brand]: <BrandProfilePopup profilePopup={profilePopup} setProfilePopup={setProfilePopup} />,
        [ROLES.shopper]: <ShopperProfilePopup profilePopup={profilePopup} setProfilePopup={setProfilePopup} />
    }

    const profileImage = {
        [ROLES.brand]: Assets.BrandAvatarLarge2,
        [ROLES.shopper]: Assets.Avatar6
    }

    const handleBrandNavigation = () => {
        if (userRole == ROLES.brand) {
            return "d-none"
        }
        else {
            return "d-block";
        }
    }

    return (
        <header className="header">
            {userProfilePopup[userRole]}

            <NotificationPopup notificationPopup={notificationPopup} setNotificationPopup={setNotificationPopup} />
            <MessagePopup messagePopup={messagePopup} setMessagePopup={setMessagePopup} />
            <SearchPopup searchPopup={searchPopup} setSearchPopup={setSearchPopup} />

            <Navbar key="xl" expand="xl" className="py-3">
                <Container fluid>
                    <NavLink to="/home">
                        <img className='d-none d-sm-block' src={Assets.LogoHorizontal} alt="" />
                        <img className='logo-small d-block d-sm-none img-fluid' src={Assets.Logo} alt="" />
                    </NavLink>
                    <div className='d-flex align-items-center justify-content-between d-xl-none'>
                        <div className="me-3 icon-wrapper d-flex align-items-center justify-content-center" onClick={() => setSearchPopup(true)}>
                            <SearchOutline className="icon" />
                        </div>
                        <div className="me-3 icon-wrapper d-flex align-items-center justify-content-center" onClick={() => setMessagePopup(true)}>
                            <MessageFillGreen className="icon" />
                        </div>
                        <div className="me-3 icon-wrapper d-flex align-items-center justify-content-center" onClick={() => setNotificationPopup(true)}>
                            <div className='notification-count'>3</div>
                            <BellFill className="icon" />
                        </div>
                        <div className="me-3 icon-wrapper d-flex align-items-center justify-content-center">
                            <img src={profileImage[userRole]} onError={e => e.target.src = Assets.ProfilePlaceholder} alt="" onClick={() => setProfilePopup(true)} />
                        </div>
                        <Navbar.Toggle onClick={() => setMenuOpen(true)} aria-controls={`offcanvasNavbar-expand-xl`} />
                    </div>

                    <Navbar.Offcanvas
                        show={menuOpen}
                        onHide={() => setMenuOpen(false)}
                        id={`offcanvasNavbar-expand-xl`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-xl`}
                        placement="end"
                        className="dashboard-sidebar"
                    >
                        <Offcanvas.Header className='justify-content-end' closeButton></Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="align-items-xl-center justify-content-xl-start flex-grow-1 ms-xl-5 ps-xl-5">
                                <NavLink onClick={() => setMenuOpen(false)} to="/home" className="py-xl-2 mx-xl-4">Home</NavLink>
                                <NavLink onClick={() => setMenuOpen(false)} to="/brands" className={`py-xl-2 mx-xl-4 ${handleBrandNavigation()}`}>Brands</NavLink>
                                <NavLink onClick={() => setMenuOpen(false)} to="/about" className="py-xl-2 mx-xl-4">About</NavLink>
                                <NavLink onClick={() => setMenuOpen(false)} to="/contact" className="py-xl-2 mx-xl-4">Contact</NavLink>
                            </Nav>
                            <div className="d-flex flex-column flex-xl-row align-items-xl-center">
                                <div className="me-xxl-20 me-xl-3">
                                    <Link to="/create-post" onClick={() => setMenuOpen(false)} className="btn-solid create-post-btn">+ CREATE POST</Link>
                                </div>
                                <div className="me-3 me-xxl-20 icon-wrapper d-none d-xl-flex align-items-xl-center justify-content-xl-center" onClick={() => setSearchPopup(true)}>
                                    <SearchOutline className="icon" />
                                </div>
                                <div className="me-3 me-xxl-20 icon-wrapper d-none d-xl-flex align-items-xl-center justify-content-xl-center" onClick={() => setMessagePopup(true)}>
                                    <MessageFillGreen className="icon" />
                                </div>
                                <div className="me-3 me-xxl-20 icon-wrapper d-none d-xl-flex align-items-xl-center justify-content-xl-center" onClick={() => setNotificationPopup(true)}>
                                    <div className='notification-count'>3</div>
                                    <BellFill className="icon" />
                                </div>
                                <div onClick={() => setProfilePopup(true)} className="icon-wrapper d-none d-xl-flex align-items-xl-center justify-content-xl-center">
                                    <img src={profileImage[userRole]} onError={e => e.target.src = Assets.ProfilePlaceholder} alt="" />
                                </div>
                                <div className='d-none d-xl-block ms-3' onClick={() => setProfilePopup(true)}>
                                    <DropDownArrow />
                                </div>
                            </div>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
        </header>
    )
}

export default DashboardNavbar