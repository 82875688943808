import React, { useState, useEffect, useContext } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import PostCommentCard from '../components/dashboard/PostCommentCard'
import PostDetailCard from '../components/dashboard/PostDetailCard'
import BlockUserPopup from '../components/popup/BlockUserPopup'
import Assets from '../constants/images'
import DashboardBreadCrumb from '../layouts/dashboard/DashboardBreadCrumb'

const BrandPostDetailPage = () => {
    const location = useLocation()
    const [blockUserPopup, setBlockUserPopup] = useState(false)
    const [data, setData] = useState({
        id: 1,
        name: "Gucci",
        profile_image: Assets.BrandAvatar1,
        time: "2 Days ago",
        caption: "I've had enough to ask for consent. I don't want to follow the rules. I want to follow my heart.",
        cover_image: Assets.BrandImageLarge3,
        shop_image: [
            {
                img: Assets.BrandImgSm1,
            },
            {
                img: Assets.BrandImgSm2,
            },
            {
                img: Assets.BrandImgSm3,
            },
            {
                img: Assets.BrandImgSm4,
            },
            {
                img: Assets.BrandImgSm5,
            },
        ]
    })

    return (
        <>
            <DashboardBreadCrumb link1="/home" link1Text="Home" link2="/brands" link2Text="Brands" link3="/brand/1" link3Text="Gucci Store" link4="#" link4Text="Posts" />
            <div className="pages post-detail-page">
                <Container>
                    <Row className="justify-content-center">
                        <Col xs={12} sm={10} md={8} lg={6} xl={5} xxl={5} className="mb-4">
                            <PostDetailCard data={data} setData={setData} blockUserPopup={blockUserPopup} setBlockUserPopup={setBlockUserPopup} />
                        </Col>

                        <Col xs={12} sm={10} md={8} lg={6} xl={5} xxl={5}>
                            <PostCommentCard />
                        </Col>
                    </Row>
                </Container>
            </div>
            <BlockUserPopup blockUserPopup={blockUserPopup} setBlockUserPopup={setBlockUserPopup} />
        </>
    )
}

export default BrandPostDetailPage
