import React from 'react'
import { Modal, Button } from "react-bootstrap"
import { NavLink, Link } from 'react-router-dom'
import Assets from '../../constants/images'
import { ToastContainer } from 'react-toastify';
import { success } from '../../constants/msg'

const UnblockAlertPopup = ({ unblockPopup, setUnblockPopup }) => {
    const unBlockUser = () => {
        setUnblockPopup(false)
        success("User unblocked")
    }

    return (
        <>
            <ToastContainer />
            <Modal
                className='theme-popup unblock-alert-popup'
                show={unblockPopup}
                onHide={() => setUnblockPopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="wrapper">
                        <div className='text-center mb-30 pb-30 border-bottom'>
                            <h3 className='w-100 d-inline-block'>UNBLOCK ALERT</h3>
                        </div>

                        <div className='content-container text-center'>
                            <div>
                                <p>
                                    Do you want to unblock <br />
                                    George Nelson ?
                                </p>
                            </div>

                            <div className="mt-5">
                                <Button className="w-100 btn-solid mb-2" onClick={() => unBlockUser()}>UNBLOCK</Button>
                                <Button className="w-100 btn-transparent" onClick={() => setUnblockPopup(false)}>CANCEL</Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default UnblockAlertPopup