import React, { useState } from 'react'
import { Button, Dropdown } from 'react-bootstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link } from 'react-router-dom'
import Assets from '../../constants/images'
import { MessageFill, DotsHorizontal } from '../../constants/svgs'
import BlockUserPopup from '../popup/BlockUserPopup'
import ReportUserPopup from '../popup/ReportUserPopup'

const ViewProfileCard = ({data}) => {
    const [blockUserPopup, setBlockUserPopup] = useState(false)
    const [reportUserPopup, setReportUserPopup] = useState(false)

    return (
        <>
            <BlockUserPopup blockUserPopup={blockUserPopup} setBlockUserPopup={setBlockUserPopup} />
            <ReportUserPopup reportUserPopup={reportUserPopup} setReportUserPopup={setReportUserPopup} />

            <div className='view-profile-card'>
                <div className="wrapper p-4 text-center">
                    <div className="profile-wrapper mx-auto">
                        <img onError={e => e.target.src = Assets.ProfilePlaceholder} src={data.img} alt="" />
                    </div>
                    <div className="mt-2">
                        <h2>{data?.name}</h2>
                        <span>{data?.location}</span>
                        <span>{data?.type}</span>
                        <span>{data?.email}</span>
                    </div>
                    <div className="mt-2 d-flex justify-content-center align-items-center">
                        <Link to="#" className="btn-solid follow-btn me-2">FOLLOW</Link>

                        <Link to="#">
                            <div className='icon-wrapper bg-deep-grey me-2'>
                                <MessageFill />
                            </div>
                        </Link>

                        <div>
                            <Dropdown className='icon-wrapper bg-white'>
                                <Dropdown.Toggle id="dropdown-basic">
                                    <DotsHorizontal />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="text-center">
                                    <Dropdown.Item className="border-bottom" onClick={() => setReportUserPopup(true)}>Report User</Dropdown.Item>
                                    <Dropdown.Item className="border-bottom" onClick={() => setBlockUserPopup(true)}>Block User</Dropdown.Item>
                                    <Dropdown.Item className="">Cancel</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewProfileCard