import React, { useState, useCallback, useRef, useEffect } from 'react'
import { Modal, Button } from "react-bootstrap"
import { NavLink, Link, Navigate, useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import Assets from '../../constants/images'
import { success } from '../../constants/msg'
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const ImageCropPopup = ({ image, setImage, imgCropPopup, setImgCropPopup }) => {
    const cropperRef = useRef(null);
    useEffect(() => {
        if (imgCropPopup) {
            setImage({
                ...image,
                file_url: image.original_file_url,
            })
        }
    }, [imgCropPopup])

    const onCropDone = () => {
        const imageElement = cropperRef?.current;
        const cropper = imageElement?.cropper;

        setImage({
            ...image,
            file_url: cropper.getCroppedCanvas().toDataURL("image/jpeg", 0.5)
        })
        setImgCropPopup(false)
    }

    return (
        <Modal
            className='theme-popup img-crop-popup'
            show={imgCropPopup}
            onHide={() => setImgCropPopup(false)}
            size="md"
            centered
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Body>
                <div className="wrapper">
                    <div className='text-center mb-30 pb-30 border-bottom'>
                        <h3 className='w-100 d-inline-block'>CROP IMAGE</h3>
                    </div>

                    <div className='content-container'>
                        <Cropper
                            src={image.file_url}
                            initialAspectRatio={16 / 9}
                            guides={false}
                            ref={cropperRef}
                            viewMode={1}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            responsive={true}
                            autoCropArea={1}
                            aspectRatio={4 / 3}
                            checkOrientation={false}
                            cropBoxResizable={true}
                        />
                    </div>

                    <div className="mt-20">
                        <Button onClick={() => onCropDone()} className="w-100 btn-solid mb-2">CROP</Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ImageCropPopup