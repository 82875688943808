import React, { useState, useEffect, useContext } from 'react'
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import { UserRole } from '../App'
import AddedPostCard from '../components/dashboard/AddedPostCard'
import ViewMyProfileCard from '../components/dashboard/ViewMyProfileCard'
import ViewProfileCard from '../components/dashboard/ViewProfileCard'
import WishlistImageCard from '../components/dashboard/WishlistImageCard'
import { ROLES } from '../constants/app-constants'
import Assets from '../constants/images'
import DashboardBreadCrumb from '../layouts/dashboard/DashboardBreadCrumb'

const ViewMyProfilePage = () => {
    const location = useLocation()
    const [userRole, setUserRole] = useContext(UserRole)
    const [wishList, setWishList] = useState([
        {
            img: Assets.PostImage1,
            isChecked: false
        },
        {
            img: Assets.PostImage5,
            isChecked: false
        },
        {
            img: Assets.PostImage11,
            isChecked: false
        },
        {
            img: Assets.PostImage12,
            isChecked: false
        },
        {
            img: Assets.PostImage13,
            isChecked: false
        },
        {
            img: Assets.PostImage18,
            isChecked: false
        },
        {
            img: Assets.PostImage19,
            isChecked: false
        },
        {
            img: Assets.PostImage20,
            isChecked: false
        },
        {
            img: Assets.PostImage21,
            isChecked: false
        },
        {
            img: Assets.PostImage22,
            isChecked: false
        },
        {
            img: Assets.PostImage23,
            isChecked: false
        },
        {
            img: Assets.PostImage24,
            isChecked: false
        },
    ])

    const ShopperTabs = () => {
        return (
            <Tabs
                defaultActiveKey="Gallery"
                id="uncontrolled-tab-example"
                className="theme-tabs"
            >
                <Tab eventKey="Gallery" title="Gallery">
                    <Row>
                        {
                            wishList.map((data, index) => (
                                <Col key={index} xs={6} sm={4} md={4} lg={3} xl={2} className="mt-4">
                                    <WishlistImageCard data={data} link="/my-profile/posts" />
                                </Col>
                            ))
                        }
                    </Row>
                </Tab>

                <Tab eventKey="Collection" title="Collection">
                    <div className='mt-3'>
                        <AddedPostCard showSelectedImageBox={false} Card={WishlistImageCard} link="/my-profile/posts" />
                    </div>
                </Tab>
            </Tabs>
        )
    }

    const BrandTabs = () => {
        return (
            <Tabs
                defaultActiveKey="Gallery"
                id="uncontrolled-tab-example"
                className="theme-tabs"
            >
                <Tab eventKey="Gallery" title="Gallery">
                    <Row>
                        {
                            wishList.map((data, index) => (
                                <Col key={index} xs={6} sm={4} md={4} lg={3} xl={2} className="mt-4">
                                    <WishlistImageCard data={data} link="/my-profile/posts" />
                                </Col>
                            ))
                        }
                    </Row>
                </Tab>

                <Tab eventKey="Product" title="Product">
                    <Row>
                        <Col xs={6} sm={4} md={4} lg={3} xl={2} className="mt-4">
                            <Link to="/my-profile/add-product">
                                <img className='w-100' src={Assets.AddProductImage} />
                            </Link>
                        </Col>

                        {
                            wishList.map((data, index) => (
                                <Col key={index} xs={6} sm={4} md={4} lg={3} xl={2} className="mt-4">
                                    <WishlistImageCard data={data} link="/my-profile/posts" />
                                </Col>
                            ))
                        }
                    </Row>
                </Tab>

                <Tab eventKey="Collection" title="Collection">
                    <div className='mt-3'>
                        <AddedPostCard showSelectedImageBox={false} Card={WishlistImageCard} link="/my-profile/posts" />
                    </div>
                </Tab>

                <Tab eventKey="Tagged" title="Tagged">
                    <Row>
                        {
                            wishList.map((data, index) => (
                                <Col key={index} xs={6} sm={4} md={4} lg={3} xl={2} className="mt-4">
                                    <WishlistImageCard data={data} link="/my-profile/posts" />
                                </Col>
                            ))
                        }
                    </Row>
                </Tab>
            </Tabs>
        )
    }

    const customTab = {
        [ROLES.shopper]: <ShopperTabs />,
        [ROLES.brand]: <BrandTabs />,
    }

    return (
        <>
            <DashboardBreadCrumb link1="/home" link1Text="Home" link2="/my-profile" link2Text="My Profile" link3="" link3Text="" link4="" link4Text="" />
            <div className='pages view-profile-page'>
                <div className="pb-4">
                    <Container fluid>
                        <Row>
                            <Col xs={12}>
                                <ViewMyProfileCard />
                            </Col>

                            <Col xs={12}>
                                {customTab[userRole]}
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default ViewMyProfilePage