import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import WebFont from 'webfontloader';
import FontCss from './assets/fonts/fonts.css';
import "./assets/scss/styles.css";

WebFont.load(
    {
        google: {
            families: ['Open Sans:300,400,500,600,700']
        },
        custom: {
            families: ['Nord-Regular', 'Outfit-Regular'],
            urls: [FontCss]
        }
    }
)

const container = document.querySelector("#root")
const root = createRoot(container)

root.render(
    <App />
);
