import React, { createContext, useEffect, useState } from 'react'
import Navigation from "./navigation/Navigation"
import { BrowserRouter } from "react-router-dom";
import moment from "moment"
import { GetAuthUserLocalStorage, GetUserRoleLocalStorage } from './services/localStorage/localStorage';

const FilterDateContext = createContext()
const AddProductContext = createContext()
const UserData = createContext()
const UserRole = createContext()

const App = () => {
    let date = moment(new Date()).format("MMM YYYY").toString();
    const [filterDate, setFilterDate] = useState(date)
    const [addProduct, setAddProduct] = useState([])
    const [userData, setUserData] = useState(GetAuthUserLocalStorage())
    const [userRole, setUserRole] = useState(GetUserRoleLocalStorage())

    useEffect(() => {
        document.body.addEventListener('mousedown', function (event) {
            if (event.detail > 1) {
                event.preventDefault();
            }
        }, false);
    }, [])


    return (
        <BrowserRouter>
            <FilterDateContext.Provider value={[filterDate, setFilterDate]}>
                <AddProductContext.Provider value={[addProduct, setAddProduct]}>
                    <UserRole.Provider value={[userRole, setUserRole]}>
                        <UserData.Provider value={[userData, setUserData]}>
                            <Navigation />
                        </UserData.Provider>
                    </UserRole.Provider>
                </AddProductContext.Provider>
            </FilterDateContext.Provider>
        </BrowserRouter>
    )
}

export default App
export { UserData, UserRole, FilterDateContext, AddProductContext }