import React from "react";
import Assets from "../../constants/images";

const RollUpCard = ({data}) => {
  return (
    <div className="rollup-card">
      <div className='text-center border-bottom mb-30 pb-30'>
        <h3>{data.name}</h3>
      </div>

      <div className="sub-name-status-div">
        <div className="d-flex align-items-center">
          <div className="profile-wrapper">
            <img src={data.profile_image} onError={(e) => (e.target.src = Assets.ProfilePlaceholder)} alt="" />
          </div>
          <h4 className="ms-3">{data.name}</h4>
        </div>
        <div className="status-div">
          <h4 className={`${data.status.toLowerCase()=== "sold" ? "color-green" : "color-orange"} ms-3`}>{data.status}</h4>
        </div>
      </div>

      <div className="rollup-card-details">
        <div className="main-rollup-card-img">
          <img src={data.product_info.img} onError={(e) => (e.target.src = Assets.ProfilePlaceholder)} alt="" />
        </div>

        <div className="rollup-card-content ms-0 mt-3 mt-sm-0 ms-sm-4">
          <h4 className="mb-1">{data.product_info.name}</h4>
          <ul>
            <li>{data.product_info.store}</li>
            <li>{`Est. Comm: ${data.product_info.est_comm}`}</li>
            <li>{`Price: ${data.product_info.price}`}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RollUpCard;
