import React, { useState } from 'react'
import { Button, FloatingLabel, Form } from 'react-bootstrap'
import { MdEmail } from "react-icons/md"
import { AiFillLock } from "react-icons/ai"
import { VscEye, VscEyeClosed } from "react-icons/vsc"
import { NavLink, useNavigate } from 'react-router-dom'
import { FcGoogle } from "react-icons/fc"
import { CgFacebook } from "react-icons/cg"
import { useForm } from 'react-hook-form'
import { VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'
import { error, success } from '../../constants/msg'
import { ToastContainer } from 'react-toastify';
import { LockFill, MessageIconFill } from '../../constants/svgs'

const Signin = () => {
    const navigate = useNavigate()
    const [showPassword, setShowPassword] = useState(false)
    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const onSubmit = (data) => {
        success("Signin Successfull")
        setTimeout(() => {
            navigate("/empty-home")
        }, 1500);
    }

    return (
        <div className='auth-card signin-form'>
            <ToastContainer />
            <div>
                <h3 className='pb-30 border-bottom mb-30'>SIGN IN</h3>
                <p className='text-black'>Transforming shopping into an experience.</p>
            </div>

            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className='mt-30'>
                    <FloatingLabel controlId="email" label="Email address *">
                        <Form.Control
                            maxLength={VALIDATIONS.EMAIL}
                            type="email"
                            placeholder="Email address *"
                            {...register("email",
                                {
                                    maxLength: {
                                        value: VALIDATIONS.EMAIL,
                                        message: VALIDATIONS_TEXT.EMAIL_MAX
                                    },
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.EMAIL_REQUIRED
                                    },
                                    pattern: {
                                        value: /\S+@\S+\.\S+/,
                                        message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                    }
                                })
                            }
                        />
                        <div className="icon-wrapper">
                            <MessageIconFill className='icon' />
                        </div>
                    </FloatingLabel>
                    {errors.email && <small className='text-start d-block text-danger mt-1'>{errors.email.message}</small>}

                    <FloatingLabel className="mt-20" controlId="password" label="Password *">
                        <Form.Control
                            minLength={VALIDATIONS.PASSWORD_MIN}
                            maxLength={VALIDATIONS.PASSWORD_MAX}
                            type={`${showPassword ? "text" : "password"}`}
                            placeholder="Password *"
                            {...register("password",
                                {
                                    minLength: {
                                        value: VALIDATIONS.PASSWORD_MIN,
                                        message: VALIDATIONS_TEXT.PASSWORD_MIN
                                    },
                                    maxLength: {
                                        value: VALIDATIONS.PASSWORD_MAX,
                                        message: VALIDATIONS_TEXT.PASSWORD_MAX
                                    },
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.PASSWORD_REQUIRED
                                    },
                                    pattern: {
                                        value: /^(?=.*?[A-Z])(?=.*?[a-z]).{8,}$/,
                                        message: VALIDATIONS_TEXT.PASSWORD_FORMAT
                                    }
                                })
                            }
                        />
                        <div className="icon-wrapper">
                            <LockFill className='icon' />
                        </div>

                        <div className="icon-wrapper-right" onClick={() => setShowPassword(!showPassword)}>
                            {
                                showPassword ?
                                    <VscEyeClosed className='icon' />
                                    :
                                    <VscEye className='icon' />
                            }
                        </div>
                    </FloatingLabel>
                    {errors.password && <small className='text-start d-block text-danger mt-1'>{errors.password.message}</small>}


                    <div className='text-end mt-20 mb-40'>
                        <NavLink className="link" to="/forgot-password">FORGOT PASSWORD</NavLink>
                    </div>

                    <Button type="submit" className='btn-solid'>SIGN IN</Button>

                    <div className="line-text">
                        <hr />
                        <span className="text">Or Sign with</span>
                    </div>

                    <Button className='mb-30 btn-transparent'>
                        <div className='d-flex align-items-center justify-content-center'>
                            <FcGoogle />
                            <span className="ms-2">Sign in with Google</span>
                        </div>
                    </Button>

                    <Button className='mb-30 btn-solid bg-blue'>
                        <div className='d-flex align-items-center justify-content-center'>
                            <CgFacebook />
                            <span className="ms-2">Sign in with Facebook</span>
                        </div>
                    </Button>

                    <span className="mb-20 d-block">Don't have an account? <NavLink className="link" to="/select-role">SIGN UP</NavLink></span>
                </div>
            </Form>
        </div>
    )
}

export default Signin