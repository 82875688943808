import React, { useContext, useEffect, useState } from 'react';
import { Modal, Button, Form, Col, Row } from "react-bootstrap"
import { useForm } from 'react-hook-form'
import { NavLink, Link } from 'react-router-dom'
import Assets from '../../constants/images'
import { Times } from '../../constants/svgs'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { FilterDateContext } from '../../App';

const MonthFilterPopup = ({ monthFilterPopup, setMonthFilterPopup }) => {
    const [value, onChange] = useState(new Date());
    const [filterDate, setFilterDate] = useContext(FilterDateContext)
    
    const filter = ()=>{
        setMonthFilterPopup(false)
    }

    return (
        <Modal
            className='theme-popup calendar-popup'
            show={monthFilterPopup}
            onHide={() => setMonthFilterPopup(false)}
            size="md"
            centered
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Body>
                <div className="wrapper">
                    <div className='d-flex text-center mb-30 pb-30 border-bottom'>
                        <h3 className='w-100 d-inline-block'>SELECT MONTH</h3>
                        <div className="text-end times-icon-wrapper" onClick={() => setMonthFilterPopup(false)}>
                            <Times />
                        </div>
                    </div>

                    <div className="calendar-wrapper">
                        <Calendar onChange={onChange} value={value} />
                        <div className='border-top'>
                            <Button onClick={() => filter()} className="mt-40 mb-10 btn-solid w-100">FILTER</Button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default MonthFilterPopup
