import React, { useContext } from 'react'
import { Button } from 'react-bootstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link } from 'react-router-dom'
import { UserRole } from '../../App'
import { ROLES } from '../../constants/app-constants'
import Assets from '../../constants/images'
import { CalendarFill, WishListFillWhite } from '../../constants/svgs'

const ViewMyProfileCard = () => {
    const [userRole, setUserRole] = useContext(UserRole)

    const userImage = {
        [ROLES.shopper]: Assets.AvatarLarge1,
        [ROLES.brand]: Assets.BrandAvatarLarge1,
    }

    return (
        <div className='view-profile-card view-myprofile-card'>
            <div className="wrapper p-4 text-center">
                <div className="profile-wrapper mx-auto">
                    <img src={userImage[userRole]} onError={e => e.target.src = Assets.ProfilePlaceholder} alt="" />
                </div>
                <div className="mt-2">
                    <h2>Elizabeth Thomas</h2>
                    <span>Toronto, Canada</span>
                    <span>elizabeth23@gmail.com</span>
                </div>
                <div className="mt-2 d-flex justify-content-center align-items-center">
                    <Link to="/edit-profile" className="btn-solid follow-btn me-2">EDIT PROFILE</Link>
                    
                    <Link to="/schedule-post">
                        <div className='icon-wrapper bg-deep-grey me-2'>
                            <CalendarFill />
                        </div>
                    </Link>
                    
                    <Link to="/my-favourites">
                        <div className='icon-wrapper bg-deep-grey'>
                            <WishListFillWhite />
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default ViewMyProfileCard