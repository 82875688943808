import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import DashboardBreadCrumb from '../layouts/dashboard/DashboardBreadCrumb'

const TermsAndConditionsPage = ({ children }) => {
    return (
        <>
            <DashboardBreadCrumb  link1="/home" link1Text="Home" link2="/settings/terms-and-conditions" link2Text="Settings" link3="#" link3Text="Terms and Conditions " link4="" link4Text="" />
            <div className='pages term-and-condition-page'>
                <Container fluid>
                    <Row>
                        <Col md={5} lg={4} xl={3} className="d-none d-md-block">
                            {children}
                        </Col>

                        <Col md={7} lg={8} xl={9}>
                            <div className="page-content-wrapper mb-4 mb-md-0 ">
                                <div className='title-wrapper border-bottom pb-30 mb-30 text-center'>
                                    <h3>Terms And Condition</h3>
                                </div>

                                <div className=''>
                                    <h4 className='mb-2'>Terms & Condition #1</h4>
                                    <p>
                                        This is dummy copy. It is not meant to be read.
                                        It has been placed here solely to demonstrate the
                                        look and feel of finished, typeset text. Only for show.
                                        He who searches for meaning here will be sorely disappointed.
                                        These words are here to provide the reader with a basic
                                        impression of how actual text will appear in its final presentation.
                                    </p>
                                </div>

                                <div className='mt-40'>
                                    <h4 className='mb-2'>Terms & Condition #2</h4>
                                    <p>
                                        This is dummy copy. It is not meant to be read.
                                        It has been placed here solely to demonstrate the
                                        look and feel of finished, typeset text. Only for show.
                                        He who searches for meaning here will be sorely disappointed.
                                        These words are here to provide the reader with a basic
                                        impression of how actual text will appear in its final presentation.
                                    </p>
                                </div>

                                <div className='mt-40'>
                                    <h4 className='mb-2'>Terms & Condition #3</h4>
                                    <p>
                                        This is dummy copy. It is not meant to be read.
                                        It has been placed here solely to demonstrate the
                                        look and feel of finished, typeset text. Only for show.
                                        He who searches for meaning here will be sorely disappointed.
                                        These words are here to provide the reader with a basic
                                        impression of how actual text will appear in its final presentation.
                                    </p>
                                </div>

                                <div className='mt-4'>
                                    <h4 className='mb-2'>Terms & Condition #4</h4>
                                    <p>
                                        This is dummy copy. It is not meant to be read.
                                        It has been placed here solely to demonstrate the
                                        look and feel of finished, typeset text. Only for show.
                                        He who searches for meaning here will be sorely disappointed.
                                        These words are here to provide the reader with a basic
                                        impression of how actual text will appear in its final presentation.
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default TermsAndConditionsPage