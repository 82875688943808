import React, { useContext, useState } from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { AiOutlinePlus } from 'react-icons/ai'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import Assets from '../../constants/images'
import { ChevronRight, Times } from '../../constants/svgs'
import { TiTimes } from "react-icons/ti"
import SchedulePostPopup from '../popup/SchedulePostPopup'
import { VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'
import { ToastContainer } from 'react-toastify'
import { success } from '../../constants/msg'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { AddProductContext } from "../../App"
import { useEffect } from 'react'
import ImageCropPopup from '../popup/ImageCropPopup'
import { FaCropAlt } from 'react-icons/fa'
import { BsCrop } from 'react-icons/bs'

const EditPostForm = () => {
    const navigate = useNavigate()
    const [schedulePostPopup, setSchedulePostPopup] = useState(false)
    const [imgCropPopup, setImgCropPopup] = useState(false)
    const [addProduct, setAddProduct] = useContext(AddProductContext)
    const [image, setImage] = useState({
        file: null,
        file_url: null
    })

    const { register, handleSubmit, watch, reset, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const onSubmit = () => {
        success("Post updated successfully")
        setAddProduct([])
        navigate("/home")
    }

    const uploadImage = (e) => {
        setImage({
            file: e.target.files[0],
            file_url: URL.createObjectURL(e.target.files[0])
        })
    }

    const removeSelectedPost = (index) => {
        addProduct.splice(index, 1)
        setAddProduct([...addProduct])
    }

    useEffect(() => {
        reset({
            description: "This is dummy copy. It is not meant to be read. It has been placed here to demonstrate."
        })
    }, [])


    return (
        <>
            <SchedulePostPopup schedulePostPopup={schedulePostPopup} setSchedulePostPopup={setSchedulePostPopup} />
            <ImageCropPopup image={image} setImage={setImage} imgCropPopup={imgCropPopup} setImgCropPopup={setImgCropPopup} />
            <ToastContainer />

            <div className={`create-edit-post-form ${addProduct.length > 0 && "h-auto"}`}>
                <div className='text-center d-flex align-items-center mb-30 pb-30 border-bottom'>
                    <h3 className='w-100 d-inline-block'>EDIT POST</h3>
                    <div className='text-end'>
                        <Link to="/home">
                            <Times />
                        </Link>
                    </div>
                </div>

                <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className={`box text-center ${!image.file && "pt-40 pb-40"}`}>
                        <label className="img-label d-block w-100" htmlFor="post-profile">
                            <input onChange={e => uploadImage(e)} className='d-none' type="file" accept="image/*" id="post-profile" />
                            {
                                image.file ?
                                    <div className="full-image">
                                        <img className='h-100 w-100' src={image.file_url} alt="" />
                                        <Button className="crop-btn" onClick={() => setImgCropPopup(true)}><BsCrop /></Button>
                                    </div>
                                    :
                                    <div className="post-profile-wrapper">
                                        <img src={Assets.PostUploadPlaceholder} alt="" />
                                    </div>
                            }
                        </label>
                    </div>

                    <div className='my-4'>
                        <label htmlFor="description"> Add description/caption</label>
                        <Form.Control
                            className='mt-1'
                            as="textarea"
                            rows={3}
                            maxLength={VALIDATIONS.DESCRIPTION_MAX}
                            {...register("description",
                                {
                                    maxLength: {
                                        value: VALIDATIONS.DESCRIPTION_MAX,
                                        message: VALIDATIONS_TEXT.DESCRIPTION_MAX
                                    },
                                    required: {
                                        value: false,
                                        message: VALIDATIONS_TEXT.DESCRIPTION_REQUIRED
                                    },
                                })
                            }
                        />
                        {errors.description && <small className='text-start d-block text-danger mt-1'>{errors.description.message}</small>}
                    </div>

                    <div className="added-product-wrapper">
                        <NavLink to="/add-post">
                            <div className='d-flex align-item-center justify-content-between border-bottom pb-3'>
                                <h5>ADD PRODUCT</h5>
                                <div>
                                    <ChevronRight />
                                </div>
                            </div>
                        </NavLink>

                        <div className="wrapper d-flex align-items-center flex-wrap pt-3">
                            <NavLink to="/add-post" className={`${addProduct.length <= 0 ? "d-none" : "d-block"}`}>
                                <div className='add-post-box mb-3 me-3 d-flex align-items-center justify-content-center'>
                                    <AiOutlinePlus className='icon' />
                                </div>
                            </NavLink>

                            {
                                addProduct.map((data, index) => (
                                    <div key={index} className='img-wrapper mb-3 me-3'>
                                        <div className='icon-times-wrapper d-flex align-items-center justify-content-center' onClick={() => removeSelectedPost(index)}>
                                            <TiTimes className="icon" />
                                        </div>
                                        <img onError={(e) => e.target.src = Assets.GeneralPlaceholder} src={data.img} alt="" />
                                    </div>
                                ))
                            }
                        </div>

                        <div className="mt-4">
                            <div className={`${addProduct.length <= 0 ? "d-none" : "d-block"}`}>
                                <Row className="justify-content-center">
                                    <Col xs={12} sm={10} className="mb-3 mb-sm-0">
                                        <Button type="submit" className="w-100 btn-solid">UPDATE POST</Button>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        </>

    )
}

export default EditPostForm