import React, { useContext, useState } from 'react'
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap'
import AddedPostCard from '../components/dashboard/AddedPostCard'
import SelectImageCard from "../components/dashboard/SelectImageCard"
import DashboardBreadCrumb from '../layouts/dashboard/DashboardBreadCrumb'
import AddFolderPopup from "../components/popup/AddFolderPopup"
import { POPUP_TYPE } from '../constants/app-constants'
import { useLocation } from 'react-router-dom'
import {AddProductContext} from "../App"

const AddPostPage = () => {
  const location = useLocation();

  return (
    <>
      <DashboardBreadCrumb link1="/home" link1Text="Home" link2="#" link2Text="Create Post" link3="" link3Text="" link4="" link4Text="" popupType={POPUP_TYPE.folder} />
      <AddFolderPopup />

      <div className='pages add-post-page'>
        <div className="pb-4">
          <Container>
            <Row>
              <Col xs={12}>
                <AddedPostCard showSelectedImageBox={true} Card={SelectImageCard} />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  )
}

export default AddPostPage