import React, { useState, useEffect, useContext } from 'react'
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import PostDetailCard from '../components/dashboard/PostDetailCard'
import DashboardBreadCrumb from '../layouts/dashboard/DashboardBreadCrumb'
import StatsCard from "../components/dashboard/StatsCard"
import { CalendarIconFill } from '../constants/svgs'
import { FilterDateContext, UserRole } from '../App'
import MonthFilterPopup from '../components/popup/MonthFilterPopup'
import Assets from '../constants/images'
import { ROLES } from '../constants/app-constants'
import BlockedUserCard from '../components/dashboard/BlockedUserCard'
import DemographicsCard from '../components/dashboard/DemographicsCard'
import BlockUserPopup from '../components/popup/BlockUserPopup'
import RollUpCard from '../components/dashboard/RollUpCard'

const StatisticsAndAnalyticsPage = () => {
    const location = useLocation()
    const [userRole, setUserRole] = useContext(UserRole)
    const [blockUserPopup, setBlockUserPopup] = useState(false)
    const [isCommentClicked, setIsCommentClicked] = useState(false)
    const [filterDate, setFilterDate] = useContext(FilterDateContext)
    const [monthFilterPopup, setMonthFilterPopup] = useState(false)

    const [data, setData] = useState([
        {
            id: 1,
            name: "Elizabeth Thomas",
            price: "$1.0",
            profile_image: Assets.Avatar1,
            time: "2 Days ago",
            caption: "I've had enough to ask for consent. I don't want to follow the rules. I want to follow my heart.",
            cover_image: Assets.PostImageLarge1,
            shop_image: [
                {
                    img: Assets.PostImageSm1,
                },
                {
                    img: Assets.PostImageSm2,
                },
                {
                    img: Assets.PostImageSm4,
                },
                {
                    img: Assets.PostImageSm5
                }
            ]
        },
        {
            id: 2,
            name: "Elizabeth Thomas",
            profile_image: Assets.Avatar1,
            time: "2 Days ago",
            price: "$1.0",
            caption: "I've had enough to ask for consent. I don't want to follow the rules. I want to follow my heart.",
            cover_image: Assets.BrandImageLarge3,
            shop_image: [
                {
                    img: Assets.PostImageSm1,
                },
                {
                    img: Assets.PostImageSm2,
                },
                {
                    img: Assets.PostImageSm4,
                },
                {
                    img: Assets.PostImageSm5
                }
            ]
        },
    ])

    const [schedulePost, setSchedulePost] = useState([
        {
            id: 1,
            name: "Elizabeth Thomas",
            profile_image: Assets.Avatar1,
            price: "$1.0",
            time: "Scheduled on: Tue, 12 July, 2022 at 4:40pm",
            caption: "I've had enough to ask for consent. I don't want to follow the rules. I want to follow my heart.",
            cover_image: Assets.PostImageLarge1,
            shop_image: [
                {
                    img: Assets.PostImageSm1,
                },
                {
                    img: Assets.PostImageSm2,
                },
                {
                    img: Assets.PostImageSm4,
                },
                {
                    img: Assets.PostImageSm5
                }
            ]
        },
        {
            id: 2,
            name: "Elizabeth Thomas",
            profile_image: Assets.Avatar1,
            price: "$1.0",
            time: "Scheduled on: Tue, 12 July, 2022 at 4:40pm",
            caption: "I've had enough to ask for consent. I don't want to follow the rules. I want to follow my heart.",
            cover_image: Assets.BrandImageLarge3,
            shop_image: [
                {
                    img: Assets.PostImageSm1,
                },
                {
                    img: Assets.PostImageSm2,
                },
                {
                    img: Assets.PostImageSm4,
                },
                {
                    img: Assets.PostImageSm5
                }
            ]
        },
    ])

    const [rollupData, setRollupData] = useState([
        {
            id: 1,
            name: "Elizabeth Thomas",
            profile_image: Assets.Avatar1,
            status: "Sold",
            product_info: {
                name: "Lipstick Red",
                store: "Amazon(US)",
                est_comm: "$12.50",
                price: "25.99",
                img: Assets.MainRollUpCardImg,
            },
        },

        {
            id: 2,
            name: "Elizabeth Thomas",
            profile_image: Assets.Avatar1,
            status: "Pending",
            cover_image: Assets.MainRollUpCardImg,
            product_info: {
                name: "Lipstick Red",
                store: "Amazon(US)",
                est_comm: "$15.50",
                price: "35.99",
                img: Assets.MainRollUpCardImg,
            },
        }
    ])

    const ShopperTabs = () => {
        return (
            <Tabs
                defaultActiveKey="PUBLISHED POSTS"
                id="uncontrolled-tab-example"
                className="theme-tabs"
            >
                <Tab eventKey="PUBLISHED POSTS" title="PUBLISHED POSTS">
                    <div className='mt-4'>
                        {
                            data.map((data, index) => (
                                <Row key={index} className='justify-content-center'>
                                    <Col key={index} xs={12} sm={10} md={8} lg={6} xl={5} xxl={5} className="mb-4">
                                        <PostDetailCard link="/statistics-and-analytics/post/1" data={data} setData={setData} blockUserPopup={blockUserPopup} setBlockUserPopup={setBlockUserPopup} />
                                    </Col>
                                </Row>
                            ))
                        }
                    </div>
                </Tab>

                <Tab eventKey="SCHEDULED POSTS" title="SCHEDULED POSTS">
                    <div className='mt-4'>
                        {
                            schedulePost.map((data, index) => (
                                <Row key={index} className='justify-content-center'>
                                    <Col key={index} xs={12} sm={10} md={8} lg={6} xl={5} xxl={5} className="mb-4">
                                        <PostDetailCard link="/statistics-and-analytics/post/1" data={data} setData={setSchedulePost} blockUserPopup={blockUserPopup} setBlockUserPopup={setBlockUserPopup} />
                                    </Col>
                                </Row>
                            ))
                        }
                    </div>
                </Tab>

                <Tab eventKey="ROLLUP" title="ROLLUP">
                    <div className='mt-4'>
                        {
                            rollupData.map((data, index) => (
                                <Row key={index} className='justify-content-center'>
                                    <Col key={index} xs={12} sm={10} md={8} lg={6} xl={5} xxl={5} className="mb-4">
                                        <RollUpCard data={data} />
                                    </Col>
                                </Row>
                            ))
                        }
                    </div>
                </Tab>
            </Tabs>
        )
    }

    const BrandTabs = () => {
        return (
            <Tabs
                defaultActiveKey="PUBLISHED POSTS"
                id="uncontrolled-tab-example"
                className="theme-tabs"
            >
                <Tab eventKey="PUBLISHED POSTS" title="PUBLISHED POSTS">
                    <div className='mt-4'>
                        {
                            data.map((data, index) => (
                                <Row key={index} className='justify-content-center'>
                                    <Col key={index} xs={12} sm={10} md={8} lg={6} xl={5} xxl={5} className="mb-4">
                                        <PostDetailCard link="/statistics-and-analytics/post/1" data={data} setData={setData} blockUserPopup={blockUserPopup} setBlockUserPopup={setBlockUserPopup} isCommentClicked={isCommentClicked} setIsCommentClicked={setIsCommentClicked} />
                                    </Col>
                                </Row>
                            ))
                        }
                    </div>
                </Tab>

                <Tab eventKey="SCHEDULED POSTS" title="SCHEDULED POSTS">
                    <div className='mt-4'>
                        {
                            schedulePost.map((data, index) => (
                                <Row key={index} className='justify-content-center'>
                                    <Col key={index} xs={12} sm={10} md={8} lg={6} xl={5} xxl={5} className="mb-4">
                                        <PostDetailCard link="/statistics-and-analytics/post/1" data={data} setData={setSchedulePost} blockUserPopup={blockUserPopup} setBlockUserPopup={setBlockUserPopup} isCommentClicked={isCommentClicked} setIsCommentClicked={setIsCommentClicked} />
                                    </Col>
                                </Row>
                            ))
                        }
                    </div>
                </Tab>

                <Tab eventKey="DEMOGRAPHICS" title="DEMOGRAPHICS">
                    <div className='mt-4'>
                        <Row className='justify-content-center'>
                            <Col xs={12} sm={10} md={8} lg={6} xl={5} xxl={5} className="mb-4">
                                <DemographicsCard link="/statistics-and-analytics/post/1" data={data} />
                            </Col>
                        </Row>
                    </div>
                </Tab>
            </Tabs>
        )
    }

    const customTab = {
        [ROLES.shopper]: <ShopperTabs />,
        [ROLES.brand]: <BrandTabs />,
    }

    return (
        <>
            <DashboardBreadCrumb link1="/home" link1Text="Home" link2="/my-profile" link2Text="My Profile" link3="#" link3Text="Statistics & Analytics" link4="" link4Text="" />
            <MonthFilterPopup monthFilterPopup={monthFilterPopup} setMonthFilterPopup={setMonthFilterPopup} />
            <BlockUserPopup blockUserPopup={blockUserPopup} setBlockUserPopup={setBlockUserPopup} />

            <div className='pages statistics-and-analytics-page'>
                <div className='pb-4'>
                    <Container fluid>
                        <Row>
                            <Col xs={12} className="text-end mb-3">
                                <div className='folder-box' onClick={() => setMonthFilterPopup(true)}>
                                    <span className="me-3">{filterDate}</span>
                                    <CalendarIconFill />
                                </div>
                            </Col>

                            <Col xs={12}>
                                <StatsCard />
                            </Col>

                            <Col xs={12}>
                                {customTab[userRole]}
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default StatisticsAndAnalyticsPage
