import React, { useState } from 'react'
import { Button, FloatingLabel, Form } from 'react-bootstrap'
import Assets from '../../constants/images'
import { FaUserAlt, FaMapMarkerAlt } from "react-icons/fa"
import { MdEmail } from "react-icons/md"
import { AiFillLock } from "react-icons/ai"
import { VscEye } from "react-icons/vsc"
import { NavLink, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { SPECIAL_CHARACTER_ARRAY, VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'
import { ToastContainer } from 'react-toastify';
import { success } from '../../constants/msg';
import { useEffect } from 'react'
import { LocationFill, MessageIconFill, UserFill } from '../../constants/svgs'

const ShopperEditProfileForm = () => {
    const navigate = useNavigate()
    const [file, setFile] = useState({
        file: null,
        file_url: null
    })

    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const onSubmit = () => {
        success("Profile updated successfully")
        setTimeout(()=>{
            navigate("/my-profile")
        },1500)
    }

    const uploadImage = (e) => {
        setFile({
            file: e.target.files[0],
            file_url: URL.createObjectURL(e.target.files[0])
        })
    }

    useEffect(() => {
        reset({
          full_name: "Anny Kings",
          address:"Toronto, Canada",
          email:"annykings@gmail.com" 
        })
    }, [])
    

    return (
        <>
            <ToastContainer />
            <div className="auth-card edit-profile-form">
                <div className='text-center d-flex align-items-center mb-30 pb-30 border-bottom'>
                    <h3 className='w-100 d-inline-block'>EDIT PROFILE</h3>
                </div>

                <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className='box text-center mt-4 mb-2'>
                        <label className="post-profile-wrapper" htmlFor="post-profile">
                            <input onChange={e => uploadImage(e)} className='d-none' type="file" accept="image/*" id="post-profile" />
                            <img src={file.file_url ? file.file_url : Assets.AvatarLarge1} onError={e => e.target.src = Assets.ProfilePlaceholder} alt="" />
                        </label>
                    </div>
                    <span className="d-block text-center">Upload/capture profile picture</span>
                    <div className='mt-30 px-40'>
                        <FloatingLabel controlId="name" label="Full name *">
                            <Form.Control
                                onKeyDown={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key) && e.preventDefault()) && e.preventDefault()}
                                maxLength={VALIDATIONS.NAME}
                                type="text"
                                placeholder="Full name *"
                                {...register("full_name",
                                    {
                                        maxLength: {
                                            value: VALIDATIONS.NAME,
                                            message: VALIDATIONS_TEXT.NAME_MAX
                                        },
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.FULL_NAME_REQUIRED
                                        },
                                    })
                                }
                            />
                            <div className="icon-wrapper">
                                <UserFill className='icon' />
                            </div>
                        </FloatingLabel>
                        {errors.full_name && <small className='text-start d-block text-danger mt-1'>{errors.full_name.message}</small>}

                        <FloatingLabel className="mt-20" controlId="address" label="Address">
                            <Form.Control
                                maxLength={VALIDATIONS.ADDRESS_MAX}
                                type="text"
                                placeholder="Address"
                                {...register("address",
                                    {
                                        maxLength: {
                                            value: VALIDATIONS.ADDRESS_MAX,
                                            message: VALIDATIONS_TEXT.ADDRESS_MAX
                                        },
                                        required: {
                                            value: false,
                                            message: VALIDATIONS_TEXT.ADDRESS_REQUIRED
                                        },
                                    })
                                }
                            />
                            <div className="icon-wrapper">
                                <LocationFill className='icon' />
                            </div>
                        </FloatingLabel>
                        {errors.address && <small className='text-start d-block text-danger mt-1'>{errors.address.message}</small>}

                        <FloatingLabel className="mt-20" controlId="email" label="Email address *">
                            <Form.Control
                                readOnly
                                maxLength={VALIDATIONS.EMAIL}
                                type="email"
                                placeholder="Email address *"
                                {...register("email",
                                    {
                                        maxLength: {
                                            value: VALIDATIONS.EMAIL,
                                            message: VALIDATIONS_TEXT.EMAIL_MAX
                                        },
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.EMAIL_REQUIRED
                                        },
                                        pattern: {
                                            value: /\S+@\S+\.\S+/,
                                            message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                        }
                                    })
                                }
                            />
                            <div className="icon-wrapper">
                                <MessageIconFill className='icon' />
                            </div>
                        </FloatingLabel>
                        {errors.email && <small className='text-start d-block text-danger mt-1'>{errors.email.message}</small>}

                        <Button type="submit" className='btn-solid my-5'>UPDATE PROFILE</Button>
                    </div>
                </Form>
            </div>
        </>
    )
}

export default ShopperEditProfileForm