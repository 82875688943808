import React, { useState } from 'react'
import { Dropdown, Form } from 'react-bootstrap'
import { DotsHorizontal } from '../../constants/svgs'
import Assets from "../../constants/images"
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { ShareOutline, MessageOutline, HeartOutline, HeartFill, WishListOutline, WishListFill } from '../../constants/svgs'
import ShowMore from 'react-show-more';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';

const PostDetailCard = ({ link, blockUserPopup, setBlockUserPopup, data, setData }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [isWishlistChecked, setIsWishlistChecked] = useState(false)
  const [isHeartChecked, setIsHeartChecked] = useState(false)

  const reportPost = () => {

  }

  return (
    <div className='post-detail-card'>
      <div className='text-center border-bottom mb-30 pb-30'>
        <h3>{data.name}</h3>
      </div>

      <div className='d-flex justify-content-between'>
        <div className='d-flex '>
          <div className="profile-wrapper">
            <img src={data.profile_image} onError={e => e.target.src = Assets.ProfilePlaceholder} alt="" />
          </div>

          <div className="ms-2 mt-0-2">
            <span className="name d-block"><b>{data.name}</b></span>
            <small className="time">{data.time}</small>
            <div className="show-more-less">
              <p>
                {data.caption}
              </p>
              <span className="price">{data?.price}</span>
            </div>
          </div>
        </div>

        <div>
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">
              <DotsHorizontal />
            </Dropdown.Toggle>
            <Dropdown.Menu className="text-center">
              <Dropdown.Item className="border-bottom" onClick={() => reportPost()}>Report Post</Dropdown.Item>
              <Dropdown.Item className="border-bottom" onClick={() => setBlockUserPopup(true)}>Block User</Dropdown.Item>
              <Dropdown.Item className="">Cancel</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <div className='post-img-wrapper mt-20'>
        <div className='post-img'>
          <Link to={link}>
            <img src={data.cover_image} onError={e => e.target.src = Assets.GeneralPlaceholder} alt="" />
          </Link>
        </div>

        <div className="mt-20 mb-20">
          <span className="shop-now">SHOP NOW</span>
        </div>

        <Swiper
          onSwiper={setThumbsSwiper}
          loop={true}
          spaceBetween={10}
          slidesPerView={1}
          breakpoints={{
            0: {
              slidesPerView: 2,
            },
            350: {
              slidesPerView: 3,
            },
            576: {
              slidesPerView: 4,
            },
            768: {
              slidesPerView: 4,
            },
            992: {
              slidesPerView: 4,
            },
            1200: {
              slidesPerView: 4,
            },
            1300: {
              slidesPerView: 5,
            },
          }}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className="swiper-bottom"
        >
          {
            data.shop_image.map((data, index) => (
              <SwiperSlide key={index}>
                <Link className="d-block h-100 w-100" to="#">
                  <img src={data.img} onError={e => e.target.src = Assets.GeneralPlaceholder} alt="" />
                </Link>
              </SwiperSlide>
            ))
          }
        </Swiper>
      </div>

      <div className="mt-30 border-top d-flex align-items-center justify-content-between">
        <div className="mt-20 d-flex align-items-center">
          <label htmlFor="heart">
            <div className='icon-wrapper me-4'>
              <input value={setIsHeartChecked} onChange={e => setIsHeartChecked(!isHeartChecked)} id="heart" className='d-none' type="checkbox" />
              {
                isHeartChecked ?
                  <HeartOutline /> :
                  <HeartFill />
              }
              <span className='ms-2'></span>
            </div>
          </label>

          <div className='icon-wrapper ms-2 me-4'>
            <MessageOutline />
            <span className='ms-2'></span>
          </div>

          <div className='icon-wrapper ms-2'>
            <ShareOutline />
          </div>
        </div>

        <div className="mt-3">
          <label htmlFor="wishlist">
            <div className='icon-wrapper'>
              <input value={isWishlistChecked} onChange={e => setIsWishlistChecked(!isWishlistChecked)} id="wishlist" className='d-none' type="checkbox" />
              {
                isWishlistChecked ?
                  <WishListOutline /> :
                  <WishListFill />
              }
              <span className=''></span>
            </div>
          </label>
        </div>
      </div>
    </div >
  )
}

export default PostDetailCard