import React, { useState, useContext } from 'react'
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap'
import { NavLink, Link, useNavigate } from 'react-router-dom'
import { AddFolderPopupContext, FilterDateContext } from '../../App';
import AddFolderPopup from '../../components/popup/AddFolderPopup';
import FilterPopup from '../../components/popup/FilterPopup';
import MonthFilterPopup from '../../components/popup/MonthFilterPopup';
import { POPUP_TYPE } from '../../constants/app-constants';
import { AddFolderFill, CalendarIconFill, FilterFill } from '../../constants/svgs';

const DashboardBreadCrumb = ({ link1, link1Text, link2, link2Text, link3, link3Text, link4, link4Text, popupType }) => {
    const navigate = useNavigate();
    const [addFolderPopup, setAddFolderPopup] = useState(false)
    const [filterPopup, setFilterPopup] = useState(false)
    const [monthFilterPopup, setMonthFilterPopup] = useState(false)
    const [filterDate, setFilterDate] = useContext(FilterDateContext)


    const ReturnPopup = (popupType) => {
        if (popupType === POPUP_TYPE.folder) {
            return (
                <div className='folder-box' onClick={() => setAddFolderPopup(true)}>
                    <span className="me-3">+ ADD FOLDER</span>
                    <AddFolderFill />
                </div>
            )
        }
        else if (popupType === POPUP_TYPE.filter){
            return(
                <div className='folder-box' onClick={() => setFilterPopup(true)}>
                    <span className="me-3">FILTER</span>
                    <FilterFill />
                </div>
            )
            
        }
        else if (popupType === POPUP_TYPE.month_filter){
            return(
                <div className='folder-box' onClick={() => setMonthFilterPopup(true)}>
                    <span className="me-3">{filterDate}</span>
                    <CalendarIconFill />
                </div>
            )
            
        }
        else {
            return (
                <></>
            )
        }
    }

    return (
        <>
            <AddFolderPopup addFolderPopup={addFolderPopup} setAddFolderPopup={setAddFolderPopup} />
            <FilterPopup filterPopup={filterPopup} setFilterPopup={setFilterPopup} />
            <MonthFilterPopup monthFilterPopup={monthFilterPopup} setMonthFilterPopup={setMonthFilterPopup} />

            <div className="dashboard-breadcrumb">
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <div className="border-bottom my-3 d-flex justify-content-between flex-column flex-sm-row">
                                <div>
                                    <Breadcrumb>
                                        <Breadcrumb.Item><Link to={link1}>{link1Text}</Link></Breadcrumb.Item>

                                        <Breadcrumb.Item><Link to={link2} className={`${(!link3 && !link4) && "active"}`}>{link2Text}</Link></Breadcrumb.Item>

                                        <Breadcrumb.Item className={`${link3 ? "d-block" : "d-none"} `}><Link to={link3} className={`${!link4 && "active"}`}>{link3Text}</Link></Breadcrumb.Item>

                                        <Breadcrumb.Item className={`${link4 ? "d-block" : "d-none"}`}><Link className="active" to="#">{link4Text}</Link></Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>

                                <div className="text-end mb-2 mb-sm-0">
                                    {ReturnPopup(popupType)}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default DashboardBreadCrumb