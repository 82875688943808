import React, { useState } from 'react'
import { Button, FloatingLabel, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { MdEmail } from "react-icons/md"
import { useNavigate } from 'react-router-dom'
import { VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'
import { success } from '../../constants/msg'
import { ToastContainer } from 'react-toastify';
import { MessageIconFill } from '../../constants/svgs'

const ForgotPasswordForm = () => {
    const navigate = useNavigate()
    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const onSubmit = (data) => {
        success("Verification code has been sent to your email")
        setTimeout(() => {
            navigate("/password-verification")
        }, 1500);
    }

    return (
        <div className='auth-card forgot-password-form'>
            <ToastContainer/>
            <div>
                <h3 className='pb-30 border-bottom mb-30'>FORGOT PASSWORD</h3>
                <p>
                    Enter the email associated with your account and <br className='d-none d-sm-block'/>
                    we'll send verification code to reset your password
                </p>
            </div>

            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className='mt-5'>
                    <FloatingLabel controlId="email" label="Email address *">
                        <Form.Control
                            maxLength={VALIDATIONS.EMAIL}
                            type="email"
                            placeholder="Email address *"
                            {...register("email",
                                {
                                    maxLength: {
                                        value: VALIDATIONS.EMAIL,
                                        message: VALIDATIONS_TEXT.EMAIL_MAX
                                    },
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.EMAIL_REQUIRED
                                    },
                                    pattern: {
                                        value: /\S+@\S+\.\S+/,
                                        message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                    }
                                })
                            }
                        />
                        <div className="icon-wrapper">
                            <MessageIconFill className='icon' />
                        </div>
                    </FloatingLabel>
                    {errors.email && <small className='text-start d-block text-danger mt-1'>{errors.email.message}</small>}

                    <Button type="submit" className='btn-solid mt-40 mb-20'>SUBMIT</Button>
                </div>
            </Form>
        </div>
    )
}

export default ForgotPasswordForm