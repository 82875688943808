import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Assets from '../../constants/images'
import { Times } from '../../constants/svgs'
import UnblockAlertPopup from '../popup/UnblockAlertPopup'

const BlockedUserCard = () => {
    const [unblockPopup, setUnblockPopup] = useState(false)
    const [blockedUserData, setBlockedUserData] = useState([
        {
            img: Assets.Avatar1,
            name: "George Nelson"
        },
        {
            img: Assets.Avatar2,
            name: "Patrick Nelson"
        },
        {
            img: Assets.Avatar3,
            name: "Amanda Nelson"
        },
        {
            img: Assets.Avatar4,
            name: "Haris Nelson"
        },
        {
            img: Assets.Avatar1,
            name: "James Nelson"
        },
        {
            img: Assets.Avatar2,
            name: "Taric Nelson"
        },
        {
            img: Assets.Avatar3,
            name: "Haris Nelson"
        },
        {
            img: Assets.Avatar4,
            name: "James Nelson"
        },
        {
            img: Assets.Avatar1,
            name: "James Nelson"
        },
        {
            img: Assets.Avatar2,
            name: "Amman Nelson"
        },
    ])

    return (
        <>
            <UnblockAlertPopup unblockPopup={unblockPopup} setUnblockPopup={setUnblockPopup} />
            <div className="block-user-card">
                <div className='text-center mb-30 pb-30 border-bottom'>
                    <h3 className='w-100 d-inline-block'>BLOCKED USERS</h3>
                </div>

                <div className="text-center">
                    <p>
                        Once you block someone, that person can no longer
                        see things you post on your profile, tag you, start
                        a conversation with you, or follow you as a friend.
                    </p>
                </div>

                <div className="box-wrapper mt-30 border-top">
                    {
                        blockedUserData.map((data, index) => (
                            <div key={index} className="box border-bottom py-3 d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                    <div className="profile-wrapper me-2">
                                        <img src={data.img} onError={e => e.target.src = Assets.ProfilePlaceholder} alt="" />
                                    </div>
                                    <h6>{data.name}</h6>
                                </div>

                                <div>
                                    <Button className="btn-solid btn-unblock" onClick={() => setUnblockPopup(true)}>UNBLOCK</Button>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    )
}

export default BlockedUserCard