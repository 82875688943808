import React from 'react'
import { Modal, Button } from "react-bootstrap"
import { NavLink, Link } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import Assets from '../../constants/images'
import { success } from '../../constants/msg'

const ReportUserPopup = ({ reportUserPopup, setReportUserPopup }) => {
    const reportUser = () => {
        setReportUserPopup(false)
        success("User reported")
    }

    return (
        <>
            <ToastContainer />
            <Modal
                className='theme-popup report-user-popup'
                show={reportUserPopup}
                onHide={() => setReportUserPopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="wrapper pb-0">
                        <div className='text-center pb-30 border-bottom'>
                            <h3 className='w-100 d-inline-block'>Report USER</h3>
                        </div>

                        <div className='content-container text-center'>
                            <ul>
                                <li className="border-bottom pt-20 pb-20">
                                    <Button className="p-0 btn-transparent" onClick={() => reportUser()}>Violance</Button>
                                </li>
                                <li className="border-bottom pt-20 pb-20">
                                    <Button className="p-0 btn-transparent" onClick={() => reportUser()}>Harrasment</Button>
                                </li>
                                <li className="border-bottom pt-20 pb-20">
                                    <Button className="p-0 btn-transparent" onClick={() => reportUser()}>Flase Information</Button>
                                </li>
                                <li className="border-bottom pt-20 pb-20">
                                    <Button className="p-0 btn-transparent" onClick={() => reportUser()}>All of the Above</Button>
                                </li>
                                <li className="pt-20">
                                    <Button className="p-0 text-grey btn-transparent" onClick={() => setReportUserPopup(false)}>Cancel</Button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ReportUserPopup