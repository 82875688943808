import React, { useState } from 'react'
import Assets from '../../constants/images'
import { CameraFill, DotsHorizontal } from "../../constants/svgs"
import { Button, Dropdown, Form, InputGroup } from "react-bootstrap"
import { IoMdSend } from "react-icons/io"
import { useForm } from 'react-hook-form'
import { BsThreeDotsVertical } from "react-icons/bs"
import { LazyLoadImage } from 'react-lazy-load-image-component'

const PostCommentCard = () => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const [comments, setComments] = useState([
        {
            img: Assets.Avatar6,
            name: "Liuna",
            comment: "The real death is that no one in the world remembers you."
        },
        {
            img: Assets.Avatar5,
            name: "Maria",
            comment: "The real death is that no one in the world remembers you."
        },
        {
            img: Assets.Avatar4,
            name: "Peter Walker",
            comment: "The real death is that no one in the world remembers you."
        },
        {
            img: Assets.Avatar3,
            name: "Cora Line",
            comment: "The real death is that no one in the world remembers you."
        },
        {
            img: Assets.Avatar2,
            name: "Ara King",
            comment: "The real death is that no one in the world remembers you."
        },
        {
            img: Assets.Avatar1,
            name: "Peter Zahng",
            comment: "The real death is that no one in the world remembers you."
        },
        {
            img: Assets.Avatar6,
            name: "Liuna",
            comment: "The real death is that no one in the world remembers you."
        },
        {
            img: Assets.Avatar5,
            name: "Maria",
            comment: "The real death is that no one in the world remembers you."
        },
        {
            img: Assets.Avatar3,
            name: "Zahng",
            comment: "The real death is that no one in the world remembers you."
        },
    ])

    const onSubmit = (data) => {
    }

    return (
        <div className='post-comment-card'>
            <div className='text-center border-bottom mb-2 pb-30'>
                <h3>Comment <span>(22)</span></h3>
            </div>

            <div className="box-wrapper">
                {
                    comments.map((data, index) => (
                        <div className="box pb-3 mt-3">
                            <div className="d-flex justify-content-between align-items-start">
                                <div className="info-box">
                                    <div className='d-flex'>
                                        <div className="profile-wrapper">
                                            <img src={data.img} onError={e => e.target.src = Assets.ProfilePlaceholder} alt="" />
                                        </div>
                                        <div className='ms-2'>
                                            <div>
                                                <h6>{data.name}</h6>
                                                <p>
                                                    {data.comment}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="time-box d-flex justify-content-end align-items-center text-end">
                                    <small className='me-1'>15min ago</small>
                                    <Dropdown>
                                        <Dropdown.Toggle id="dropdown-basic">
                                            <BsThreeDotsVertical className='icon' />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item className="border-bottom">Delete Comment</Dropdown.Item>
                                            <Dropdown.Item>Cancel</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                    ))
                }

            </div>

            <div className="message-container">
                <div className="message-wrapper mt-3">
                    <div className="profile-wrapper">
                        <img src={Assets.Avatar1} onError={e => e.target.src = Assets.ProfilePlaceholder} alt="" />
                    </div>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <InputGroup className="p-2">
                            <Form.Control as="textarea" rows={0} placeholder="Write a comment..." />
                            <InputGroup.Text>
                                <Button type="submit" className="btn-send me-2">
                                    <IoMdSend className='icon' />
                                </Button>
                                <label className="icon-wrapper" htmlFor="camera">
                                    <input accept='image/*' type="file" id="camera" className='d-none' />
                                    <CameraFill />
                                </label>
                            </InputGroup.Text>
                        </InputGroup>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default PostCommentCard