import React, { useState } from 'react'
import { Button, FloatingLabel, Form } from 'react-bootstrap'
import Assets from '../../constants/images'
import { FaUserAlt, FaMapMarkerAlt } from "react-icons/fa"
import { MdEmail } from "react-icons/md"
import { AiFillLock } from "react-icons/ai"
import { VscEye, VscEyeClosed } from "react-icons/vsc"
import { NavLink, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { SPECIAL_CHARACTER_ARRAY, VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'
import { success } from '../../constants/msg'
import { ToastContainer } from 'react-toastify';
import { LocationFill, LockFill, MessageIconFill, UserFill } from '../../constants/svgs'
import { RegisterUser } from "../../services/auth/auth"

const SignupShopperForm = () => {
    const navigate = useNavigate()
    const [showPassword, setShowPassword] = useState(false)
    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const onSubmit = (data) => {
        console.log(data)
        // success("Signup Successfull")
        // setTimeout(() => {
        //     navigate("/account-verification")
        // }, 1500);
    }

    const [file, setFile] = useState({
        file: null,
        file_url: null
    })

    const uploadImage = (e) => {
        setFile({
            file: e.target.files[0],
            file_url: URL.createObjectURL(e.target.files[0])
        })
    }

    return (
        <div className='auth-card signup-form'>
            <ToastContainer />
            <div>
                <h3 className='pb-30 border-bottom mb-30'>SIGN UP</h3>
                <p className='text-black'>Transforming shopping into an experience.</p>
            </div>

            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className='mt-30'>
                    <label className='profile-img-wrapper' htmlFor="profile">
                        <input className="d-none" onChange={e => uploadImage(e)} type="file" id="profile" name="profile" accept="image/*" />
                        <img src={file.file_url ? file.file_url : Assets.ProfileUploadPlaceholder} alt="" />
                    </label>
                    <p className='mt-2'>Upload profile picture</p>
                </div>

                <div className='mt-30'>
                    <FloatingLabel controlId="name" label="Full name *">
                        <Form.Control
                            onKeyDown={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key) && e.preventDefault()) && e.preventDefault()}
                            maxLength={VALIDATIONS.NAME}
                            type="text"
                            placeholder="Full name *"
                            {...register("full_name",
                                {
                                    maxLength: {
                                        value: VALIDATIONS.NAME,
                                        message: VALIDATIONS_TEXT.NAME_MAX
                                    },
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.FULL_NAME_REQUIRED
                                    },
                                })
                            }
                        />
                        <div className="icon-wrapper">
                            <UserFill className='icon' />
                        </div>
                    </FloatingLabel>
                    {errors.full_name && <small className='text-start d-block text-danger mt-1'>{errors.full_name.message}</small>}

                    <FloatingLabel className="mt-20" controlId="address" label="Address">
                        <Form.Control
                            maxLength={VALIDATIONS.ADDRESS_MAX}
                            type="text"
                            placeholder="Address"
                            {...register("address",
                                {
                                    maxLength: {
                                        value: VALIDATIONS.ADDRESS_MAX,
                                        message: VALIDATIONS_TEXT.ADDRESS_MAX
                                    },
                                    required: {
                                        value: false,
                                        message: VALIDATIONS_TEXT.ADDRESS_REQUIRED
                                    },
                                })
                            }
                        />
                        <div className="icon-wrapper">
                            <LocationFill className='icon' />
                        </div>
                    </FloatingLabel>
                    {errors.address && <small className='text-start d-block text-danger mt-1'>{errors.address.message}</small>}


                    <FloatingLabel className="mt-20" controlId="email" label="Email address *">
                        <Form.Control
                            maxLength={VALIDATIONS.EMAIL}
                            type="email"
                            placeholder="Email address *"
                            {...register("email",
                                {
                                    maxLength: {
                                        value: VALIDATIONS.EMAIL,
                                        message: VALIDATIONS_TEXT.EMAIL_MAX
                                    },
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.EMAIL_REQUIRED
                                    },
                                    pattern: {
                                        value: /\S+@\S+\.\S+/,
                                        message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                    }
                                })
                            }
                        />
                        <div className="icon-wrapper">
                            <MessageIconFill className='icon' />
                        </div>
                    </FloatingLabel>
                    {errors.email && <small className='text-start d-block text-danger mt-1'>{errors.email.message}</small>}


                    <FloatingLabel className="mt-20" controlId="password" label="Password *">
                        <Form.Control
                            minLength={VALIDATIONS.PASSWORD_MIN}
                            maxLength={VALIDATIONS.PASSWORD_MAX}
                            type={`${showPassword ? "text" : "password"}`}
                            placeholder="Password *"
                            {...register("password",
                                {
                                    minLength: {
                                        value: VALIDATIONS.PASSWORD_MIN,
                                        message: VALIDATIONS_TEXT.PASSWORD_MIN
                                    },
                                    maxLength: {
                                        value: VALIDATIONS.PASSWORD_MAX,
                                        message: VALIDATIONS_TEXT.PASSWORD_MAX
                                    },
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.PASSWORD_REQUIRED
                                    },
                                    pattern: {
                                        value: /^(?=.*?[A-Z])(?=.*?[a-z]).{8,}$/,
                                        message: VALIDATIONS_TEXT.PASSWORD_FORMAT
                                    }
                                })
                            }
                        />
                        <div className="icon-wrapper">
                            <LockFill className='icon' />
                        </div>

                        <div className="icon-wrapper-right" onClick={() => setShowPassword(!showPassword)}>
                            {
                                showPassword ?
                                    <VscEyeClosed className='icon' />
                                    :
                                    <VscEye className='icon' />
                            }
                        </div>
                    </FloatingLabel>
                    {errors.password && <small className='text-start d-block text-danger mt-1'>{errors.password.message}</small>}


                    <Button type="submit" className='mt-40 mb-30 btn-solid'>SIGN UP</Button>
                    <span className="mb-20 d-block">Already have an account? <NavLink className="link" to="/signin">SIGN IN</NavLink></span>
                </div>
            </Form>
        </div>
    )
}

export default SignupShopperForm