import React, { useState, useEffect, useContext } from 'react'
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import PostDetailCard from '../components/dashboard/PostDetailCard'
import DashboardBreadCrumb from '../layouts/dashboard/DashboardBreadCrumb'
import StatsCard from "../components/dashboard/StatsCard"
import { POPUP_TYPE } from '../constants/app-constants'
import Assets from '../constants/images'
import BlockUserPopup from '../components/popup/BlockUserPopup'

const SchedulePostPage = () => {
  const location = useLocation()
  const [blockUserPopup, setBlockUserPopup] = useState(false)
  const [isCommentClicked, setIsCommentClicked] = useState(false)
  const [data, setData] = useState([
    {
      id: 1,
      name: "Elizabeth Thomas",
      profile_image: Assets.Avatar1,
      time: "2 Days ago",
      caption: "I've had enough to ask for consent. I don't want to follow the rules. I want to follow my heart.",
      cover_image: Assets.PostImageLarge1,
      price: "$1.0",
      shop_image: [
        {
          img: Assets.PostImageSm1,
        },
        {
          img: Assets.PostImageSm2,
        },
        {
          img: Assets.PostImageSm4,
        },
        {
          img: Assets.PostImageSm5
        }
      ]
    },
    {
      id: 2,
      name: "Elizabeth Thomas",
      profile_image: Assets.Avatar1,
      time: "2 Days ago",
      caption: "I've had enough to ask for consent. I don't want to follow the rules. I want to follow my heart.",
      cover_image: Assets.PostImageLarge1,
      price: "$1.0",
      shop_image: [
        {
          img: Assets.PostImageSm1,
        },
        {
          img: Assets.PostImageSm2,
        },
        {
          img: Assets.PostImageSm4,
        },
        {
          img: Assets.PostImageSm5
        }
      ]
    }
  ])

  return (
    <>
      <DashboardBreadCrumb link1="/home" link1Text="Home" link2="/my-profile" link2Text="My Profile" link3="#" link3Text="Schedule Post" link4="" link4Text="" popupType={POPUP_TYPE.month_filter} />
      <BlockUserPopup blockUserPopup={blockUserPopup} setBlockUserPopup={setBlockUserPopup} />

      <div className='pages statistics-and-analytics-page'>
        <div className='pb-4'>
          <Container fluid>
            {
              data.map((data, index) => (
                <Row key={index} className='justify-content-center'>
                  <Col key={index} xs={12} sm={10} md={8} lg={6} xl={5} xxl={5} className="mb-4">
                    <PostDetailCard link="/schedule-post/1" data={data} setData={setData} blockUserPopup={blockUserPopup} setBlockUserPopup={setBlockUserPopup} isCommentClicked={isCommentClicked} setIsCommentClicked={setIsCommentClicked} />
                  </Col>
                </Row>
              ))
            }
          </Container>
        </div>
      </div>
    </>
  )
}

export default SchedulePostPage