import React, { useState, useEffect, useContext } from 'react'
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import AddedPostCard from '../components/dashboard/AddedPostCard'
import ViewProfileCard from '../components/dashboard/ViewProfileCard'
import WishlistImageCard from '../components/dashboard/WishlistImageCard'
import Assets from '../constants/images'
import DashboardBreadCrumb from '../layouts/dashboard/DashboardBreadCrumb'

const ViewProfilePage = ({ children }) => {
    const location = useLocation()
    const [wishList, setWishList] = useState([
        {
            img: Assets.BrandImage16,
            isChecked: false
        },
        {
            img: Assets.BrandImage17,
            isChecked: false
        },
        {
            img: Assets.BrandImage18,
            isChecked: false
        },
        {
            img: Assets.BrandImage19,
            isChecked: false
        },
        {
            img: Assets.BrandImage20,
            isChecked: false
        },
        {
            img: Assets.BrandImage21,
            isChecked: false
        },
        {
            img: Assets.BrandImage12,
            isChecked: false
        },
        {
            img: Assets.BrandImage13,
            isChecked: false
        },
        {
            img: Assets.BrandImage14,
            isChecked: false
        },
        {
            img: Assets.BrandImage5,
            isChecked: false
        },
        {
            img: Assets.BrandImage10,
            isChecked: false
        },
        {
            img: Assets.BrandImage11,
            isChecked: false
        },
    ])
    
    const [data, setData] = useState({
        img: Assets.BrandAvatarLarge1,
        name:"Gucci Store",
        type:"clothing",
        location:"Toronto, Canada",
        email:"elizabeth23@gmail.com"
    })

    return (
        <>
            <DashboardBreadCrumb link1="/home" link1Text="Home" link2="/brands" link2Text="Brands" link3="#" link3Text="Gucci Store" link4="" link4Text="" />
            <div className='pages view-profile-page'>
                <div className="pb-4">
                    <Container fluid>
                        <Row>
                            <Col xs={12}>
                                <ViewProfileCard data={data} />
                            </Col>

                            <Col xs={12}>
                                <Row>
                                    {
                                        wishList.map((data, index) => (
                                            <Col key={index} xs={6} sm={4} md={4} lg={3} xl={2} className="mt-4">
                                                <WishlistImageCard data={data} link="/brand/posts" />
                                            </Col>
                                        ))
                                    }
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default ViewProfilePage