import React, { useState, useEffect, useContext } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import PostCommentCard from '../components/dashboard/PostCommentCard'
import PostDetailCard from '../components/dashboard/PostDetailCard'
import PostDetailStatsCard from '../components/dashboard/PostDetailStatsCard'
import BlockUserPopup from '../components/popup/BlockUserPopup'
import Assets from '../constants/images'
import DashboardBreadCrumb from '../layouts/dashboard/DashboardBreadCrumb'

const PostDetailStatsPage = () => {
    const location = useLocation()
    const [blockUserPopup, setBlockUserPopup] = useState(false)
    const [isCommentClicked, setIsCommentClicked] = useState(false)
    const [data, setData] = useState({
        id: 1,
        name: "Elizabeth Thomas",
        profile_image: Assets.Avatar1,
        time: "August 7, 2022",
        caption: "Gucci Hand Bag",
        cover_image: Assets.BrandImageLarge3,
        extra_products: [
            {
                name:"Travel Bag",
                store:"Amazon(US)",
                est_comm:"$1.50",
                price:"25.99",
                img: Assets.PostImage32,
            },
            {
                name:"Travel Bag",
                store:"Amazon(US)",
                est_comm:"$1.50",
                price:"25.99",
                img: Assets.PostImage33,
            },
            {
                name:"Travel Bag",
                store:"Amazon(US)",
                est_comm:"$1.50",
                price:"25.99",
                img: Assets.PostImage5,
            },
        ]
    })

    return (
        <>
            <DashboardBreadCrumb link1="/home" link1Text="Home" link2="/my-profile" link2Text="My Profile" link3="/statistics-and-analytics" link3Text="Statistics & Analytics" link4="#" link4Text="Post Details" />
            <div className="pages post-detail-stats-page">
                <Container>
                    <Row className='justify-content-center'>
                        <Col xs={12} sm={10} md={8} lg={6} xl={5} xxl={5} className="mb-4">
                            <PostDetailStatsCard data={data} setData={setData} blockUserPopup={blockUserPopup} setBlockUserPopup={setBlockUserPopup} isCommentClicked={isCommentClicked} setIsCommentClicked={setIsCommentClicked} />
                        </Col>
                    </Row>
                </Container>
            </div>
            <BlockUserPopup blockUserPopup={blockUserPopup} setBlockUserPopup={setBlockUserPopup} />
        </>
    )
}

export default PostDetailStatsPage
